const Options = {
  // 시간
  time: [
    { label: '실시간', value: 'now' },
    { label: '1시간', value: '1h' },
    { label: '6시간', value: '6h' },
    { label: '12시간', value: '12h' },
    { label: '24시간', value: '24h' },
  ],
  // 시각화
  visual: [
    { label: '장소별', value: 'equipment', mode: 'NORMAL' },
    { label: '밀집도', value: 'distribution', mode: 'HEATMAP' },
    { label: '방향별', value: 'direction', mode: 'DIRECTION' },
    // { label: '흐름별', value: 'flow', mode: 'NORMAL' },
  ],
  // 승인상태 TEMP(임시저장), REQ(승인요청), WTH(승인회수), REJ(승인반려), CON(승인완료)
  approvalStatusHasTempSave: [
    { label: '전체', value: 'all', state: 'ALL' },
    { label: '임시저장', value: 'tempSave', state: 'TEMP' },
    { label: '승인요청', value: 'request', state: 'REQ' },
    { label: '승인회수', value: 'withdraw', state: 'WTH' },
    { label: '승인반려', value: 'reject', state: 'REJ' },
    { label: '승인완료', value: 'complete', state: 'CON' },
  ],
  // 승인상태
  approvalStatus: [
    { label: '전체', value: 'all', state: 'ALL' },
    { label: '승인요청', value: 'request', state: 'REQ' },
    { label: '승인회수', value: 'withdraw', state: 'WTH' },
    { label: '승인반려', value: 'reject', state: 'REJ' },
    { label: '승인완료', value: 'complete', state: 'CON' },
  ],
  // 홍보영역
  prmArea: [
    { label: '전체', value: 'all' },
    { label: '메인슬라이드', value: 'main' },
    { label: '바 배너', value: 'bar' },
    { label: '박스배너', value: 'box' },
    { label: '통배너', value: 'fullscreen' },
  ],
  // 홍보타입
  prmType: [
    { label: '전체', value: 'all' },
    { label: 'ESG', value: 'esg' },
    { label: '소상공인', value: 'smallBsns' },
    { label: '지자체', value: 'localGov' },
  ],
  // 요일
  dayOfWeek: [
    { label: '월', value: 'mon' },
    { label: '화', value: 'tue' },
    { label: '수', value: 'wed' },
    { label: '목', value: 'ths' },
    { label: '금', value: 'fri' },
    { label: '토', value: 'sat' },
    { label: '일', value: 'sun' },
  ],
  // 노출위치
  expPosition: [
    { label: '프로파일01', value: 1 },
    { label: '프로파일02', value: 2 },
    { label: '프로파일03', value: 3 },
  ],
  timeOptions: [
    {
      label: '00:00 - 04:00',
      value: '0-4',
      startTime: '00:00',
      endTime: '04:00',
    },
    {
      label: '04:00 - 08:00',
      value: '4-8',
      startTime: '04:00',
      endTime: '08:00',
    },
    {
      label: '08:00 - 12:00',
      value: '8-12',
      startTime: '08:00',
      endTime: '12:00',
    },
    {
      label: '12:00 - 16:00',
      value: '12-16',
      startTime: '12:00',
      endTime: '16:00',
    },
    {
      label: '16:00 - 20:00',
      value: '16-20',
      startTime: '16:00',
      endTime: '20:00',
    },
    {
      label: '20:00 - 24:00',
      value: '20-24',
      startTime: '20:00',
      endTime: '24:00',
    },
  ],
  promoStateOptions: [
    {
      value: 'NONE',
      className: '',
      label: '미등록',
    },
    {
      value: 'ONAIR',
      className: 'addon',
      label: '홍보중',
    },
    {
      value: 'EXPIRED',
      className: 'end',
      label: '종료',
    },
    {
      value: 'DELETED',
      className: 'cancel',
      label: '취소',
    },
  ],
  admTypeOptions: [
    {
      value: 'MASTER',
      state: 'MASTER',
      label: '레오테크',
      type: 'NORMAL',
    },
    {
      value: 'ADM-HEAD',
      state: 'ADM_HEAD',
      label: 'ESG 본사',
      type: 'NORMAL',
    },
    {
      value: 'ADM-SUB',
      state: 'ADM_SUB',
      label: 'ESG 계열사',
      type: 'NORMAL',
    },
    {
      value: 'ADM-GOV',
      state: 'ADM_GOV',
      label: '지자체',
      type: 'NORMAL',
    },
    {
      value: 'ADM-STORE',
      state: 'ADM_STORE',
      label: '소상공인',
      isStore: true,
      type: 'STORE',
    },
    // {
    //   value: 'ADM-OTHER',
    //   state: 'ADM_OTHER',
    //   label: '기타',
    //   type: 'NORMAL',
    // },
  ],
};

export default Options;
