/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const updatedOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      position: 'nearest',
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        color: 'rgba(255, 255, 255, 0.3)', // 격자 라인 색상 설정
      },
      ticks: {
        color: '#fff',
        font: {
          size: 12,
        },
        angle: 0, // 폰트를 수평으로 표시
      },
      display: false, // x축 라벨 생략
    },
    y: {
      grid: {
        color: 'rgba(255, 255, 255, 0.3)', // 격자 라인 색상 설정
      },
      ticks: {
        color: '#fff',
        font: {
          size: 12,
        },
        // eslint-disable-next-line object-shorthand, func-names
        callback: function (value) {
          // const roundedValue = Math.round(value / 1000); // 반올림하여 천단위로 변환
          const roundedValue = value;
          const formattedValue = new Intl.NumberFormat().format(roundedValue); // 천단위 콤마 추가
          return `${formattedValue}`;
        },
      },
    },
  },
};

// const backgroundColors = [
//   '#F94144',
//   '#F3722C',
//   '#F8961E',
//   '#F9C74F',
//   '#90BE6D',
// ];

// const labels = [
//   '올리브영앞',
//   '지하차도 입구',
//   '공원입구',
//   '산동지하차도 입구',
//   'Others',
// ];

// export const dataObj = {
//   labels: [
//     '올리브영앞',
//     '지하차도 입구',
//     '공원입구',
//     '산동지하차도 입구',
//     'Others',
//   ],
//   datasets: [
//     {
//       // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       label: [
//         '올리브영앞',
//         '지하차도 입구',
//         '공원입구',
//         '산동지하차도 입구',
//         'Others',
//       ], // 데이터셋에 label 속성을 설정
//       data: [3459160, 1465136, 930183, 595329, 1566208],
//       backgroundColor: labels.map((_, index) => backgroundColors[index]),
//       categoryPercentage: 1.0,
//       barPercentage: 0.5,
//     },
//   ],
// };

export default React.memo(function VerticalBarChart({ barChartData }) {
  // console.log('setBarChartData@@@@@@@@@@@', barChartData);
  return (
    <div
      style={{
        height: '220px',
        width: '100%',
        display: 'flex',
      }}
    >
      <Bar
        options={updatedOptions}
        data={barChartData}
        style={{ flex: 1, width: '100%', alignSelf: 'flex-end' }}
      />
    </div>
  );
});
