/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react';
import {
  WiDaySunny,
  WiCloud,
  WiCloudy,
  WiDayCloudy,
  WiNightCloudy,
  WiRain,
  WiRainMix,
  WiSnow,
  WiRaindrop,
  WiSnowWind,
  WiNightClear,
} from 'weather-icons-react';
import DisconnectIcon from './DisconnectIcon';

function WeatherIconDisplay({
  ptyValue = 0,
  skyValue,
  size = 24,
  color = '#000',
  fontSize = 16,
  error = false,
}) {
  const [isNight, setIsNight] = useState(false);

  const today = new Date();
  const hours = today.getHours();

  useEffect(() => {
    if (hours >= 5 && hours < 19) {
      setIsNight(false);
    } else {
      setIsNight(true);
    }
  }, [hours]);

  // console.log('hours : ', hours, 'isNight : ', isNight);

  // console.log(skyValue);
  const textStyle = {
    color,
    fontSize,
  };

  // signal_disconnected
  if (error) return <DisconnectIcon size={size} color={color} />;

  if (ptyValue === '1')
    // 비(PTY-1)
    return (
      <>
        <WiRain size={size} color={color} />
        <span style={textStyle}>비</span>
      </>
    );

  // 비/눈(PTY-2)
  if (ptyValue === '2')
    return (
      <>
        <WiRainMix size={size} color={color} />
        <span style={textStyle}>비/눈</span>
      </>
    );

  // 눈(PTY-3)
  if (ptyValue === '3')
    return (
      <>
        <WiSnow size={size} color={color} />
        <span style={textStyle}>눈</span>
      </>
    );

  // 빗방울(PTY-5)
  if (ptyValue === '5')
    return (
      <>
        <WiRaindrop size={size} color={color} />
        <span style={textStyle}>빗방울</span>
      </>
    );

  // 빗방울눈날림(PTY-6)
  if (ptyValue === '6')
    return (
      <>
        <WiRainMix size={size} color={color} />
        <span style={textStyle}>빗방울눈날림</span>
      </>
    );

  // 눈날림(PTY-7)
  if (ptyValue === '7')
    return (
      <>
        <WiSnowWind size={size} color={color} />
        <span style={textStyle}>눈날림</span>
      </>
    );

  // 맑음(SKY-1)
  if (skyValue === '1')
    return (
      <>
        {isNight ? (
          <WiNightClear size={size} color={color} />
        ) : (
          <WiDaySunny size={size} color={color} />
        )}
        <span style={textStyle}>맑음</span>
      </>
    );

  // 구름많음(SKY-3)
  if (skyValue === '3')
    return (
      <>
        {isNight ? (
          <WiNightCloudy size={size} color={color} />
        ) : (
          <WiDayCloudy size={size} color={color} />
        )}
        <span style={textStyle}>구름많음</span>
      </>
    );

  // 흐림(SKY-4)
  if (skyValue === '4') {
    return (
      <>
        <WiCloud size={size} color={color} />
        <span style={textStyle}>흐림</span>
      </>
    );
  }

  return null;
}

export default WeatherIconDisplay;
