/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-const */
/* eslint-disable react/no-array-index-key */
import { getFloating, getExceptAps } from '@common/crud';
import React, { useEffect, useRef, useState } from 'react';
import { Container, Modal, Dropdown, Form } from 'react-bootstrap';
import { images } from '@assets';
import { Header, CustomSelect, CustomModal, Loading } from '@components';
import { Utils, Options, RouterPath } from '@common';
import classNames from 'classnames';

// 기본보기
import { useHistory } from 'react-router-dom';

// map
import ReactDOMServer from 'react-dom/server';
import {
  Container as MapDiv,
  Marker,
  NaverMap,
  useNavermaps,
} from 'react-naver-maps';
import PolygonMarker from './PolygonMarker';
import PolygonLine from './PolygonLine';
import HeatMapMarker from './HeatMapMarker';

import Chart from './Chart';

import Sig from './json/sig_100.json'; // 위경도 값

import * as RssiToCoordnates from './func/rssiToCoordnates'; // 분포별 관련

import arrowInfo from './json/arrow-info.json'; // 방향별 관련
import apList from './json/ap-list.json'; // AP 리스트

// 커스텀 마커
import './assets/css/CustomMarker.css';
import { getFloatingAPC } from '../../common/crud';
import ArrowMain from './ArrowMain';
// import { backgroundImage } from 'html2canvas/dist/types/css/property-descriptors/background-image';

const HEATMAP_SPOT_POPULATION_RATIO = 100;
const HEATMAP_SPOT_MAX_RADIUS = 400;

export default React.memo(function Dashboard(props) {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const navermaps = useNavermaps();
  const [mapTypeId, setMapTypeId] = useState(navermaps.MapTypeId.NORMAL);
  const buttons = [
    {
      typeId: navermaps.MapTypeId.NORMAL,
      text: '일반지도',
    },
    {
      typeId: navermaps.MapTypeId.TERRAIN,
      text: '지형도',
    },
    {
      typeId: navermaps.MapTypeId.SATELLITE,
      text: '위성지도',
    },
    {
      typeId: navermaps.MapTypeId.HYBRID,
      text: '겹쳐보기',
    },
  ];
  // dropdown
  const [selectedTime, setSelectedTime] = useState(Options.time[0]); // 현황 기준
  const [selectedVisual, setSelectedVisual] = useState(Options.visual[0].value); // 시각화

  // 가입 지역 리스트
  // const [profileListShow, setProfileListShow] = useState(false);
  const [selectedProfileTitle, setSelectedProfileTitle] = useState('');
  const [mapMarkerDepth, setMapMarkerDepth] = useState(0);
  const [profileId, setProfileId] = useState(0);

  // 방향별 보행, 이동수단 구분
  const [directionType, setDirectionType] = useState('blue');

  // 밀집도 히트맵 타입 라디오
  const [heatmapType, setHeatmapType] = useState('type-a');

  const naverMap = useRef();
  const [mapModeId, setMapModeId] = useState('NORMAL');
  const [polygonList, setPolygonList] = useState([]);
  const [maxMinZoom, setMaxMinZoom] = useState(11);
  const [moveCenter, setMoveCenter] = useState();
  const [polygonMarkerList, setPolygonMarkerList] = useState([]);
  const [selectUnit, setSelectUnit] = useState();
  const [defaultCenter, setDefaultCenter] = useState();

  const [heatmapDataList, setHeatmapDataList] = useState([]); // 히트맵 - type-a
  const [heatmapSpotsData, setHeatmapSpotsData] = useState([]); // 히트맵 - type-b

  const [floatPopShow, setFloatPopShow] = useState(false);

  // 유동 API 연동
  // 유동 상황판 - 전체 정보
  const [dashboardStateTotal, setDashboardStateTotal] = useState({});
  const [dashboardStateHour, setDashboardStateHour] = useState({});

  // 유동 상황판 - 행정동 정보
  const [dashboardStateRegion, setDashboardStateRegion] = useState({});

  // 유동 상황판 - 데이터 가공
  const [dashboardValues, setDashboardValues] = useState({});
  const [dashboardMapValues, setDashboardMapValues] = useState({}); // 프로파일 정보
  const [directData, setDirectData] = useState([]); // 방향별 API 데이터

  // 유동 상황판 - AP 정보
  const [unitListAP, setUnitListAP] = useState([]);
  const [NoSignalUnitListAP, setNoSignalUnitListAP] = useState([]);

  useEffect(() => {
    const result = apList.filter(
      pl => !unitListAP.some(ul => ul.apmac_id === pl.apmac_id),
    );
    setNoSignalUnitListAP(result);

    const heatmapSpotArr = unitListAP.map(el => {
      const { longitude, latitude, count } = el;
      const spots = Math.ceil(count / HEATMAP_SPOT_POPULATION_RATIO);

      const lngLatArr = [];

      for (let i = 0; i < spots; i += 1) {
        lngLatArr.push([longitude, latitude]);
      }

      return {
        heatmapDataList: lngLatArr,
        radius:
          count > HEATMAP_SPOT_MAX_RADIUS ? HEATMAP_SPOT_MAX_RADIUS : count,
      };
    });

    setHeatmapSpotsData(heatmapSpotArr);
  }, [unitListAP]);

  // 유동 APC API
  const [apcState, setApcState] = useState([]);

  // 차트 데이터(누계)
  const [chartData, setChartData] = useState({
    labels: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23, 24,
    ],
    datasets: [
      {
        data: [],
        borderColor: '#326bff',
        borderWidth: 1,
        pointBackgroundColor: '#326bff',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointHoverBorderColor: '#326bff',
        pointHoverBorderWidth: 1,
        yAxisID: 'y',
      },
    ],
  });

  const createChartDataTotal = data => {
    const tempArr = [];
    const tempObj = {
      labels: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24,
      ],
      datasets: [
        {
          data: [],
          borderColor: '#326bff',
          borderWidth: 1,
          pointBackgroundColor: '#326bff',
          pointBorderColor: '#fff',
          pointBorderWidth: 2,
          pointHoverBorderColor: '#326bff',
          pointHoverBorderWidth: 1,
          yAxisID: 'y',
        },
      ],
    };

    data.forEach(el => tempArr.push(el.count));
    tempObj.datasets[0].data = tempArr;
    setChartData(tempObj);
  };

  // 차트 데이터(AP별)
  const [chartDataAP, setChartDataAP] = useState({
    labels: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23, 24,
    ],
    datasets: [
      {
        data: [],
        borderColor: '#305399',
        borderWidth: 1,
        pointBackgroundColor: '#305399',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointHoverBorderColor: '#305399',
        pointHoverBorderWidth: 1,
        yAxisID: 'y',
      },
    ],
  });

  const createChartDataAP = data => {
    const tempArr = [];
    const tempObj = {
      labels: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24,
      ],
      datasets: [
        {
          data: [],
          borderColor: '#305399',
          borderWidth: 1,
          pointBackgroundColor: '#305399',
          pointBorderColor: '#fff',
          pointBorderWidth: 2,
          pointHoverBorderColor: '#305399',
          pointHoverBorderWidth: 1,
          yAxisID: 'y',
        },
      ],
    };

    data.forEach(el => tempArr.push(el.count));
    tempObj.datasets[0].data = tempArr;
    setChartDataAP(tempObj);
  };

  // 유동 상황판 구축 장비 현황 API
  const getAPC = async url => {
    try {
      setIsLoading(true);

      const responseAPC = await getFloatingAPC(url);
      const { list: apcList } = responseAPC.data;

      const responseApList = await getExceptAps();
      const { data: exceptApList } = responseApList.data;

      const result = exceptApList.reduce((acc, curr) => {
        const selectedAp = apcList.find(ap => ap.ap_mac === curr.apMacAddress);
        return [...acc, selectedAp];
      }, []);

      if (result) {
        setIsLoading(false);
      }

      const temp = {};
      temp.normal = result.filter(el => el.ap_status === 'run').length;
      temp.abnormal = result.filter(el => el.ap_status === 'unjoin').length;
      temp.total = temp.normal + temp.abnormal;
      setApcState(temp);
    } catch (e) {
      console.error(e);
    }
  };

  // 방향별 API 데이터 유동 인구 합계
  const sumDirectionCount = (apmacID, data) => {
    const tempObj = arrowInfo.find(el => el.apmac_id === apmacID).direction;
    const tempArr = Object.keys(tempObj);

    const result = {};

    tempArr.forEach((v, i) => {
      result[v] = { count: 0, deg: tempObj[v].deg, colorLevel: '' };
      result[v].count = data.reduce(
        (a, b) => (tempObj[v].apmacIds.includes(b.toAP_id) ? a + b.count : a),
        0,
      );
      result[v].colorLevel =
        result[v].count >= tempObj[v].colorLevel.danger
          ? 'danger'
          : result[v].count >= tempObj[v].colorLevel.serious
          ? 'serious'
          : result[v].count >= tempObj[v].colorLevel.boundary
          ? 'boundary'
          : 'normal';
    });
    return result;
  };

  // 유동 상황판 조회 API
  const getDashboard = async (url, rest) => {
    try {
      const response = await getFloating(url, rest);

      switch (url) {
        case 'state':
          // 시간별
          if (rest.time > 0) {
            if (rest.depth === 0) {
              setDashboardStateHour(response.data.data);
            }
            if (rest.depth === 1) {
              setDashboardStateRegion(response.data.data);
            }
            if (rest.depth === 2) {
              setDashboardStateHour(response.data.data);

              const {
                batch_hour_profileDTO: batchHourProfileDTO,
                batch_hour_profileDTOList: batchHourProfileDTOList,
                batch_visitors_profileDTO: batchVisitorsProfileDTO,
              } = response.data.data;
              setDashboardValues({
                ...dashboardValues,
                total: batchHourProfileDTO,
                hour: batchHourProfileDTOList,
                visitors: batchVisitorsProfileDTO,
                apc: {
                  ...apcState,
                },
              });

              const tempArr = response.data.data.traffic_hourDTOList;
              setUnitListAP([...tempArr]);
            }
          }
          // 실시간
          if (rest.time === 0) {
            if (rest.depth === 0) {
              setDashboardStateTotal(response.data.data);
            }
            // 프로파일 수량 정보 확인
            if (rest.depth === 1) {
              setDashboardStateRegion(response.data.data);
            }
            if (rest.depth === 2) {
              setDashboardStateTotal(response.data.data);
              const tempArr = response.data.data.batch_now_equipDTOList;
              setUnitListAP([...tempArr]);
            }
          }
          break;
        case 'detail':
          // console.log('response.data.data : ', response.data.data);
          createChartDataAP(response.data.data);
          break;
        case 'direct':
          setDirectData(sumDirectionCount(rest.apmac, response.data.data));
          break;
        default:
          break;
      }
    } catch (e) {
      console.error(e);
    }
  };

  let selectedTimeValue =
    selectedTime.value === '1h'
      ? 1
      : selectedTime.value === '6h'
      ? 6
      : selectedTime.value === '12h'
      ? 12
      : selectedTime.value === '24h'
      ? 24
      : 0;

  const currentUnitMarker = useRef(); // 방향별 현재 선택된 apmac_id, 시간별 조회 리렌더링용

  const changeBreadcrumb = ({ depth, id, zoom = null }) => {
    setMapMarkerDepth(depth);
    setProfileId(id);
    setMaxMinZoom(zoom);
  };

  useEffect(() => {
    getDashboard('state', {
      time: selectedTimeValue,
      depth: mapMarkerDepth,
      id: profileId,
    });
    if (mapMarkerDepth === 0 && profileId === 0) {
      getDashboard('state', { time: selectedTimeValue, depth: 1, id: 1 }); // * 행정구역별(추후 행정구역 구분시 기능 구현 예정)
    }
    // getDashboard('direct', { time: selectedTimeValue, apmac: 2 });
    getAPC('apStatus');
    if (currentUnitMarker.current && mapModeId === 'DIRECTION')
      getDashboard('direct', {
        apmac: currentUnitMarker.current,
        time: selectedTimeValue,
        type: directionType === 'blue' ? 0 : 1,
      });
  }, [selectedTimeValue, mapMarkerDepth, profileId, directionType]);

  // 유동 상황판 데이터 가공
  useEffect(() => {
    // 시간별
    if (selectedTimeValue > 0) {
      // 프로파일별
      if (profileId > 0) {
        if (mapMarkerDepth === 1) {
          setDashboardValues({
            ...dashboardValues,
            total: dashboardStateRegion.batch_hour_regionDTO,
            hour: dashboardStateRegion.batch_hour_regionDTOList,
            visitors: dashboardStateRegion.batch_month_regionDTO,
            apc: {
              ...apcState,
            },
          });
          setDashboardMapValues({
            ...dashboardMapValues,
            profile: {
              count: dashboardStateRegion.batch_hour_profileDTOList?.length,
              list: dashboardStateRegion.batch_hour_profileDTOList,
            },
          });
        }
        // 전체
      } else {
        setDashboardValues({
          ...dashboardValues,
          total: dashboardStateHour.batch_hour_totalDTO,
          hour: dashboardStateHour.batch_hour_totalDTOList,
          visitors: dashboardStateHour.batch_visitors_totalDTO,
          apc: {
            ...apcState,
          },
        });
        setDashboardMapValues({
          ...dashboardMapValues,
          profile: {
            count: dashboardStateRegion.batch_hour_profileDTOList?.length,
            list: dashboardStateRegion.batch_hour_profileDTOList,
          },
        });
      }
    }
    // 실시간
    if (selectedTimeValue === 0) {
      // 프로파일별
      if (profileId > 0) {
        if (mapMarkerDepth === 2) {
          setDashboardValues({
            ...dashboardValues,
            total: dashboardStateTotal.batch_now_profileDTO,
            hour: dashboardStateTotal.batch_hour_profileDTOList,
            visitors: dashboardStateTotal.batch_visitors_profileDTO,
            apc: {
              ...apcState,
            },
          });
        }
        if (mapMarkerDepth === 1) {
          setDashboardValues({
            ...dashboardValues,
            total: dashboardStateRegion.batch_now_regionDTO,
            hour: dashboardStateRegion.batch_hour_regionDTOList,
            visitors: dashboardStateRegion.batch_month_regionDTO,
            apc: {
              ...apcState,
            },
          });
          setDashboardMapValues({
            ...dashboardMapValues,
            profile: {
              count: dashboardStateRegion.batch_now_profileDTOList?.length,
              list: dashboardStateRegion.batch_now_profileDTOList,
            },
          });
        }
        // 전체
      } else {
        setDashboardValues({
          ...dashboardValues,
          total: dashboardStateTotal.batch_total_nowDTO,
          hour: dashboardStateTotal.batch_hour_totalDTOList,
          visitors: dashboardStateTotal.batch_visitors_totalDTO,
          apc: {
            ...apcState,
          },
        });
        setDashboardMapValues({
          ...dashboardMapValues,
          profile: {
            count: dashboardStateRegion.batch_now_profileDTOList?.length,
            list: dashboardStateRegion.batch_now_profileDTOList,
          },
        });
      }
    }
    if (dashboardValues.hour) createChartDataTotal(dashboardValues.hour); // 금일 유동인구 누계 차트
  }, [dashboardStateTotal, apcState]);

  // 가입 지역, 미가입 지역 위경도 정보 가공
  function initJoinLatLng(joinTrueOrFalse, sigCd) {
    const tempObj = { isJoin: joinTrueOrFalse, paths: [] };
    const tempArr = [];

    tempArr.push(
      Sig.features
        .find(item => item.properties.SIG_CD === sigCd)
        .geometry.coordinates.flat()
        .flat(),
    );

    tempObj.paths = tempArr[0].map(el =>
      JSON.parse(`{"lat": ${el[1]}, "lng": ${el[0]}}`),
    );

    return tempObj;
  }

  // 가입 지역, 미가입 지역 표시
  useEffect(() => {
    setDefaultCenter(new navermaps.LatLng(35.2538433, 128.6402609)); // 창원 위경도
    setPolygonMarkerList(tempPolygonCenter);
    setPolygonList(prevPolygonList => [
      ...prevPolygonList,
      initJoinLatLng(true, '48121'),
    ]);
    setPolygonList(prevPolygonList => [
      ...prevPolygonList,
      initJoinLatLng(false, '48123'),
    ]);
    setPolygonList(prevPolygonList => [
      ...prevPolygonList,
      initJoinLatLng(false, '48129'),
    ]);
    setPolygonList(prevPolygonList => [
      ...prevPolygonList,
      initJoinLatLng(false, '48127'),
    ]);
    setPolygonList(prevPolygonList => [
      ...prevPolygonList,
      initJoinLatLng(false, '48125'),
    ]);
    return () => {
      setPolygonList([]);
    };
  }, []);

  const [rssiDataState, setRssiDataState] = useState();

  // 분포별(히트맵) API
  const getRssiData = async (time, profile) => {
    try {
      const url = 'distribution';
      const params = { time, profile };
      // console.log('time, profile : ', params);
      const response = await getFloating(url, params);
      // console.log('response.data.data : ', response.data.data);
      setRssiDataState(response.data.data);
    } catch (e) {
      console.error(e);
    }
  };

  // console.log('rssiDataState : ', rssiDataState);

  useEffect(() => {
    if (mapModeId === 'HEATMAP') getRssiData(selectedTimeValue, profileId);
    if (selectedTimeValue !== 0) setHeatmapType('type-a');
  }, [selectedTimeValue, mapModeId]);

  // * RSSI 정보로 위경도 계산
  useEffect(() => {
    setHeatmapDataList([]);
    if (rssiDataState)
      rssiDataState.forEach(el1 => {
        const toAPCoord = apList.find(el2 => +el1.toAP_id === el2.apmac_id);
        const fromAPCoord = apList.find(el2 => el1.fromAP_id === el2.apmac_id);
        const { rssi } = el1;

        // apList 등록되지 않은 AP 존재할 경우에 대한 코드
        if (toAPCoord === undefined || fromAPCoord === undefined) {
          console.error(
            `apmac_id ${el1.fromAP_id}는(은) apList에 등록되지 않음!`,
          );
        } else {
          const resultCoord = RssiToCoordnates.calPoint(
            toAPCoord.latitude,
            toAPCoord.longitude,
            fromAPCoord.latitude,
            fromAPCoord.longitude,
            rssi,
          );
          setHeatmapDataList(prev => [...prev, resultCoord]);
        }
      });
  }, [rssiDataState]);

  // 가입 지역 선택시 타이틀 변경
  useEffect(() => {
    const tempProfileList =
      selectedTimeValue > 0
        ? dashboardStateRegion.batch_hour_profileDTOList
        : dashboardStateRegion.batch_now_profileDTOList;
    if (naverMap && mapMarkerDepth === 2) {
      const selectData = tempProfileList?.find(
        el => el.profile_id === profileId,
      );
      const center = new navermaps.LatLng(
        selectData?.latitude,
        selectData?.longitude,
      );
      setMoveCenter(center);
      setMaxMinZoom(18);
      naverMap.current.setZoom(18, true);
    }
  }, [profileId, mapMarkerDepth]);

  useEffect(() => {
    if (naverMap && moveCenter && profileId !== 0) {
      naverMap.current.panTo(moveCenter);
      naverMap.current.refresh();
    }
  }, [moveCenter]);

  return (
    <main id="dashboard" className="prevent-user-select">
      {isLoading && <Loading />}
      <Header title="유동 상황판" />
      <Container fluid>
        <MapDiv
          style={{
            width: '100%',
            height: '100%',
          }}
          className="map-box"
          fallback={null}
        >
          <NaverMap
            ref={naverMap}
            defaultCenter={defaultCenter}
            maxZoom={maxMinZoom}
            minZoom={maxMinZoom}
            mapTypeId={mapTypeId}
          >
            {mapModeId === 'HEATMAP' && (
              <>
                {heatmapType === 'type-a' && (
                  <HeatMapMarker heatmapDataList={heatmapDataList} />
                )}
                {heatmapType === 'type-b' &&
                  heatmapSpotsData.map((el, idx) => {
                    return (
                      <HeatMapMarker
                        key={`heatmap-spot-${idx}`}
                        {...el}
                        opacity={0.2}
                        inReverse
                        colormap="YIOrRd"
                      />
                    );
                  })}
              </>
            )}
            {/* 행정구역별 */}
            {profileId === 0 &&
              mapMarkerDepth === 0 &&
              polygonMarkerList.map((v, i) => {
                const content = ReactDOMServer.renderToString(
                  // 행정동 프로파일 사각 마커
                  <div>
                    <div className="custom-circle-marker">
                      <div className="custom-circle-marker-text">
                        {dashboardMapValues.profile.count}
                      </div>
                      <div className="custom-circle-market-text-2">창원시</div>
                    </div>
                  </div>,
                );
                return (
                  <PolygonMarker
                    key={`polygon-marker-${i}`}
                    data={v}
                    content={content}
                    onClick={() => {
                      setProfileId(1); // 행정동별(depth: 1, id: 1)
                      setMapMarkerDepth(1);
                      setMaxMinZoom(13);
                      naverMap.current.setZoom(13, true);
                    }}
                  />
                );
              })}
            {/* 프로파일별 */}
            {mapMarkerDepth === 1 &&
              dashboardMapValues.profile.count &&
              dashboardMapValues.profile.list.map((v, i) => {
                const content = ReactDOMServer.renderToString(
                  <div
                    className="custom-marker wide"
                    style={{ display: 'grid', paddingTop: '10px' }}
                  >
                    <div className="custom-marker-title">{v.profile_nm}</div>
                    <div className="custom-marker-icon">
                      {directionType === 'blue' ? (
                        <svg
                          width="13"
                          height="22"
                          viewBox="0 0 13 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.12 9.8H13V8H9.38L7.38 4.67C7.08 4.17 6.54 3.83 5.92 3.83C5.74 3.83 5.58 3.86 5.42 3.91L0 5.6V10.8H1.8V7.13L3.91 6.47L0 21.8H1.8L4.67 13.69L7 16.8V21.8H8.8V15.39L6.31 10.85L7.04 7.98M8 3.6C9 3.6 9.8 2.8 9.8 1.8C9.8 0.8 9 0 8 0C7 0 6.2 0.8 6.2 1.8C6.2 2.8 7 3.6 8 3.6Z"
                            fill="white"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="16"
                          viewBox="0 0 20 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.92478 15.787H2.88321C3.48519 15.787 3.95252 15.3197 3.95252 14.7177V13.3474C5.71887 13.4583 8.32484 13.5454 10.4239 13.5454C12.5229 13.5454 15.1289 13.4662 16.8873 13.3474V14.7177C16.8873 15.3197 17.3546 15.787 17.9645 15.787H18.915C19.5249 15.787 19.9923 15.3197 19.9923 14.7177V10.0207C19.9923 8.61866 19.7309 7.84242 18.9942 6.884L18.2893 5.99686C17.9962 4.57903 17.4734 3.08199 17.1962 2.49584C16.7526 1.55326 15.8972 0.998801 14.8041 0.848305C14.2259 0.769096 12.5308 0.729492 10.4239 0.729492C8.31692 0.729492 6.61393 0.777017 6.04363 0.848305C4.95055 0.98296 4.08718 1.55326 3.65153 2.49584C3.3743 3.08199 2.85152 4.57903 2.55845 5.99686L1.8535 6.884C1.10894 7.84242 0.855469 8.61866 0.855469 10.0207V14.7177C0.855469 15.3197 1.3228 15.787 1.92478 15.787ZM4.33272 5.26814C4.5149 4.48398 4.86342 3.43842 5.10105 3.01862C5.33075 2.59881 5.59214 2.42456 6.08323 2.36119C6.64562 2.28198 8.00008 2.23445 10.4239 2.23445C12.8397 2.23445 14.2021 2.26614 14.7645 2.36119C15.2556 2.43248 15.5091 2.59881 15.7467 3.01862C15.9922 3.4305 16.317 4.48398 16.5071 5.26814C16.5942 5.60874 16.4437 5.76716 16.0873 5.75131C14.8675 5.68003 13.3308 5.60082 10.4239 5.60082C7.51691 5.60082 5.98026 5.68003 4.76045 5.75131C4.39609 5.76716 4.26144 5.60874 4.33272 5.26814ZM4.61788 11.3276C3.86539 11.3276 3.28717 10.7573 3.28717 10.0048C3.28717 9.24441 3.86539 8.67411 4.61788 8.67411C5.37036 8.67411 5.94066 9.24441 5.94066 10.0048C5.94066 10.7573 5.37036 11.3276 4.61788 11.3276ZM16.2299 11.3276C15.4694 11.3276 14.8991 10.7573 14.8991 10.0048C14.8991 9.24441 15.4694 8.67411 16.2299 8.67411C16.9823 8.67411 17.5526 9.24441 17.5526 10.0048C17.5526 10.7573 16.9823 11.3276 16.2299 11.3276ZM8.28523 10.9949C7.71493 10.9949 7.31889 10.5989 7.31889 10.0365C7.31889 9.47412 7.71493 9.07807 8.28523 9.07807H12.5625C13.1249 9.07807 13.5209 9.47412 13.5209 10.0365C13.5209 10.5989 13.1249 10.9949 12.5625 10.9949H8.28523Z"
                            fill="white"
                          />
                        </svg>
                      )}
                    </div>
                    <div className="custom-marker-value">
                      <link
                        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap"
                        rel="stylesheet"
                      />
                      {Utils.changeNumberComma(v.count)}
                    </div>
                  </div>,
                );
                return (
                  <PolygonMarker
                    key={`polygon-marker-profile-${i}`}
                    data={{ lat: v.latitude, lng: v.longitude }}
                    content={content}
                    onClick={() => {
                      const tempObj = {
                        time: selectedTimeValue,
                        depth: 2,
                        id: v.profile_id,
                      };
                      setMapMarkerDepth(2);
                      setSelectedProfileTitle(v.profile_nm);
                      setProfileId(v.profile_id);
                      getDashboard('state', tempObj); // 프로파일 정보
                    }}
                  />
                );
              })}
            {/* 가입지역, 미가입 지역 표시 */}
            {profileId === 0 &&
              mapMarkerDepth === 0 &&
              polygonList.map((v, i) => {
                return <PolygonLine key={`polygon-line-${i}`} data={v} />;
              })}
            {/* 프로파일별 AP 조회 */}
            {mapMarkerDepth === 2 &&
              NoSignalUnitListAP.map((v, i) => {
                let content = ReactDOMServer.renderToString(
                  // 커스텀 마커(Custom Marker)
                  <div
                    className="custom-marker nosign"
                    style={{
                      background: 'rgba(80, 80, 80, 0.5)',
                      display: 'grid',
                      paddingTop: '10px',
                    }}
                  >
                    <div className="custom-marker-title-nosign">{v.ap_nm}</div>
                    <div className="custom-marker-icon-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                      >
                        <path
                          d="M790-56 414-434q-47 11-87.5 33T254-346l-84-86q32-32 69-56t79-42l-90-90q-41 21-76.5 46.5T84-516L0-602q32-32 66.5-57.5T140-708l-84-84 56-56 736 736-58 56Zm-310-64q-42 0-71-29.5T380-220q0-42 29-71t71-29q42 0 71 29t29 71q0 41-29 70.5T480-120Zm236-238-29-29-29-29-144-144q81 8 151.5 41T790-432l-74 74Zm160-158q-77-77-178.5-120.5T480-680q-21 0-40.5 1.5T400-674L298-776q44-12 89.5-18t92.5-6q142 0 265 53t215 145l-84 86Z"
                          fill="rgba(255, 255, 255, 0.5)"
                        />
                      </svg>
                    </div>
                  </div>,
                );
                return (
                  <UnitMarker
                    key={`no-signal-ap-${i}`}
                    data={v}
                    content={content}
                    onClick={e => {}}
                  />
                );
              })}
            {mapMarkerDepth === 2 &&
              unitListAP.map((v, i) => {
                let arrowShow = false;
                let content = ReactDOMServer.renderToString(
                  // 커스텀 마커(Custom Marker)
                  <div
                    className={`custom-marker ${
                      directionType === 'red' && mapModeId === 'DIRECTION'
                        ? 'pink'
                        : null
                    }`}
                    style={
                      !(directionType !== 'blue' && mapModeId === 'DIRECTION')
                        ? {
                            // background:
                            //   'linear-gradient(to bottom, #2787cc, #195078)',
                            background: '#142286',
                            display: 'grid',
                            paddingTop: '10px',
                          }
                        : {
                            // background:
                            //   'linear-gradient(to bottom, #F85F8B, #FF2564)',
                            background: '#F6960A',
                            color: '#002060',
                            display: 'grid',
                            paddingTop: '10px',
                          }
                    }
                  >
                    <div className="custom-marker-title">{v.ap_nm}</div>
                    <div className="custom-marker-icon">
                      {!(
                        directionType !== 'blue' && mapModeId === 'DIRECTION'
                      ) ? (
                        <svg
                          width="13"
                          height="22"
                          viewBox="0 0 13 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.12 9.8H13V8H9.38L7.38 4.67C7.08 4.17 6.54 3.83 5.92 3.83C5.74 3.83 5.58 3.86 5.42 3.91L0 5.6V10.8H1.8V7.13L3.91 6.47L0 21.8H1.8L4.67 13.69L7 16.8V21.8H8.8V15.39L6.31 10.85L7.04 7.98M8 3.6C9 3.6 9.8 2.8 9.8 1.8C9.8 0.8 9 0 8 0C7 0 6.2 0.8 6.2 1.8C6.2 2.8 7 3.6 8 3.6Z"
                            fill="white"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="22"
                          viewBox="0 -960 960 960"
                          fill="#002060"
                        >
                          <path d="M620-740q-33 0-56.5-23.5T540-820q0-33 23.5-56.5T620-900q33 0 56.5 23.5T700-820q0 33-23.5 56.5T620-740ZM432-540l88 92v248h-80v-200L312-512q-14-12-19-25t-5-31q0-18 5.5-30.5T312-624l112-112q13-13 27.5-18.5T484-760q18 0 32.5 5.5T544-736l76 76q27 27 63 43.5t81 16.5v80q-63 0-114-22.5T560-604l-32-32-96 96Zm-232 60q85 0 142.5 57.5T400-280q0 85-57.5 142.5T200-80q-85 0-142.5-57.5T0-280q0-85 57.5-142.5T200-480Zm0 340q57 0 98.5-41.5T340-280q0-57-41.5-98.5T200-420q-57 0-98.5 41.5T60-280q0 57 41.5 98.5T200-140Zm560-340q85 0 142.5 57.5T960-280q0 85-57.5 142.5T760-80q-85 0-142.5-57.5T560-280q0-85 57.5-142.5T760-480Zm0 340q57 0 98.5-41.5T900-280q0-57-41.5-98.5T760-420q-57 0-98.5 41.5T620-280q0 57 41.5 98.5T760-140Z" />
                        </svg>
                      )}
                    </div>
                    <div className="custom-marker-value">
                      <link
                        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap"
                        rel="stylesheet"
                      />
                      {Utils.changeNumberComma(v.count)}
                    </div>
                  </div>,
                );
                if (selectUnit && selectUnit.apmac_id === v.apmac_id) {
                  switch (mapModeId) {
                    case 'DIRECTION':
                      arrowShow = true;
                      content = ReactDOMServer.renderToString(
                        <div style={{ display: 'inline-block' }}>
                          <div
                            // className="custom-marker"
                            className={`custom-marker ${
                              directionType === 'red' &&
                              mapModeId === 'DIRECTION'
                                ? 'pink'
                                : null
                            }`}
                            style={
                              directionType === 'blue'
                                ? {
                                    // background:
                                    //   'linear-gradient(to bottom, #2787cc, #195078)',
                                    background: '#142286',
                                    display: 'grid',
                                    paddingTop: '10px',
                                  }
                                : {
                                    // background:
                                    //   'linear-gradient(to bottom, #F85F8B, #FF2564)',
                                    background: '#f6960a',
                                    display: 'grid',
                                    paddingTop: '10px',
                                  }
                            }
                          >
                            <div className="custom-marker-title">{v.ap_nm}</div>
                            <div className="custom-marker-icon">
                              {directionType === 'blue' ? (
                                <svg
                                  width="13"
                                  height="22"
                                  viewBox="0 0 13 22"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.12 9.8H13V8H9.38L7.38 4.67C7.08 4.17 6.54 3.83 5.92 3.83C5.74 3.83 5.58 3.86 5.42 3.91L0 5.6V10.8H1.8V7.13L3.91 6.47L0 21.8H1.8L4.67 13.69L7 16.8V21.8H8.8V15.39L6.31 10.85L7.04 7.98M8 3.6C9 3.6 9.8 2.8 9.8 1.8C9.8 0.8 9 0 8 0C7 0 6.2 0.8 6.2 1.8C6.2 2.8 7 3.6 8 3.6Z"
                                    fill="white"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="22"
                                  viewBox="0 -960 960 960"
                                  fill="#002060"
                                >
                                  <path d="M620-740q-33 0-56.5-23.5T540-820q0-33 23.5-56.5T620-900q33 0 56.5 23.5T700-820q0 33-23.5 56.5T620-740ZM432-540l88 92v248h-80v-200L312-512q-14-12-19-25t-5-31q0-18 5.5-30.5T312-624l112-112q13-13 27.5-18.5T484-760q18 0 32.5 5.5T544-736l76 76q27 27 63 43.5t81 16.5v80q-63 0-114-22.5T560-604l-32-32-96 96Zm-232 60q85 0 142.5 57.5T400-280q0 85-57.5 142.5T200-80q-85 0-142.5-57.5T0-280q0-85 57.5-142.5T200-480Zm0 340q57 0 98.5-41.5T340-280q0-57-41.5-98.5T200-420q-57 0-98.5 41.5T60-280q0 57 41.5 98.5T200-140Zm560-340q85 0 142.5 57.5T960-280q0 85-57.5 142.5T760-80q-85 0-142.5-57.5T560-280q0-85 57.5-142.5T760-480Zm0 340q57 0 98.5-41.5T900-280q0-57-41.5-98.5T760-420q-57 0-98.5 41.5T620-280q0 57 41.5 98.5T760-140Z" />
                                </svg>
                              )}
                            </div>
                            <div className="custom-marker-value">
                              <link
                                href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap"
                                rel="stylesheet"
                              />
                              {Utils.changeNumberComma(v.count)}
                            </div>
                          </div>
                        </div>,
                      );
                      break;
                    default:
                      content = ReactDOMServer.renderToString(
                        // 커스텀 마커
                        <div style={{ display: 'inline-block' }}>
                          <div
                            className="custom-marker"
                            style={{ display: 'grid', paddingTop: '10px' }}
                          >
                            <div className="custom-marker-title">{v.ap_nm}</div>
                            <div className="custom-marker-icon">
                              <svg
                                width="13"
                                height="22"
                                viewBox="0 0 13 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.12 9.8H13V8H9.38L7.38 4.67C7.08 4.17 6.54 3.83 5.92 3.83C5.74 3.83 5.58 3.86 5.42 3.91L0 5.6V10.8H1.8V7.13L3.91 6.47L0 21.8H1.8L4.67 13.69L7 16.8V21.8H8.8V15.39L6.31 10.85L7.04 7.98M8 3.6C9 3.6 9.8 2.8 9.8 1.8C9.8 0.8 9 0 8 0C7 0 6.2 0.8 6.2 1.8C6.2 2.8 7 3.6 8 3.6Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                            <div className="custom-marker-value">
                              <link
                                href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap"
                                rel="stylesheet"
                              />
                              {Utils.changeNumberComma(v.count)}
                            </div>
                          </div>
                        </div>,
                      );
                      break;
                  }
                  switch (mapModeId) {
                    case 'HEATMAP':
                      arrowShow = true;
                      content = ReactDOMServer.renderToString(
                        <div style={{ display: 'inline-block' }}>
                          <div
                            className="custom-marker"
                            style={{
                              // background:
                              //   'linear-gradient(to bottom, #2787cc, #195078)',
                              background: '#142286',
                              display: 'grid',
                              paddingTop: '10px',
                            }}
                          >
                            <div className="custom-marker-title">{v.ap_nm}</div>
                            <div className="custom-marker-icon">
                              <svg
                                width="13"
                                height="22"
                                viewBox="0 0 13 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.12 9.8H13V8H9.38L7.38 4.67C7.08 4.17 6.54 3.83 5.92 3.83C5.74 3.83 5.58 3.86 5.42 3.91L0 5.6V10.8H1.8V7.13L3.91 6.47L0 21.8H1.8L4.67 13.69L7 16.8V21.8H8.8V15.39L6.31 10.85L7.04 7.98M8 3.6C9 3.6 9.8 2.8 9.8 1.8C9.8 0.8 9 0 8 0C7 0 6.2 0.8 6.2 1.8C6.2 2.8 7 3.6 8 3.6Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                            <div className="custom-marker-value">
                              <link
                                href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap"
                                rel="stylesheet"
                              />
                              {Utils.changeNumberComma(v.count)}
                            </div>
                          </div>
                        </div>,
                      );
                      break;
                    default:
                  }
                }
                return (
                  <React.Fragment key={`marker-ap-${i}`}>
                    <UnitMarker
                      data={v}
                      content={content}
                      onClick={e => {
                        setDirectData({});
                        setSelectUnit(e);
                        setFloatPopShow(mapModeId !== 'DIRECTION' && true);
                        if (mapModeId === 'DIRECTION') {
                          getDashboard('direct', {
                            apmac: v.apmac_id,
                            time: selectedTimeValue,
                            type: directionType === 'blue' ? 0 : 1,
                          });
                          currentUnitMarker.current = v.apmac_id;
                        } else {
                          getDashboard('detail', { apmac: v.apmac_id });
                        }
                      }}
                    />
                    {mapModeId === 'DIRECTION' &&
                      arrowShow &&
                      Object.keys(directData).map((w, j) => (
                        <UnitArrowMarker
                          key={`arrow-show-${j}`}
                          data={v}
                          content={ReactDOMServer.renderToString(
                            <ArrowMain
                              transform={directData[w].deg}
                              color={directData[w].colorLevel}
                              value={directData[w].count}
                            />,
                          )}
                          clickable={false}
                        />
                      ))}
                  </React.Fragment>
                );
              })}
            {/* 좌측 상단 breadcrumb */}
            <ul className="map-breadcrumb">
              <li
                onClick={() => {
                  changeBreadcrumb({ depth: 0, id: 0, zoom: 11 });
                  setMapModeId('normal');
                }}
                style={{ cursor: 'pointer' }}
              >
                경상남도
              </li>
              {mapMarkerDepth >= 1 && (
                <li
                  onClick={() => {
                    changeBreadcrumb({ depth: 1, id: 1, zoom: 13 });
                    setMapModeId('normal');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  창원시
                </li>
              )}
              {mapMarkerDepth >= 2 && <li>{selectedProfileTitle}</li>}
              {/* {profileId !== 0 && <li>{selectedProfileTitle}</li>} */}
            </ul>
            <div className="map-fix-right map-permission">
              {mapMarkerDepth === 0 && (
                // 가입지역, 미가입지역
                <div className="map-help">
                  <span>가입 지역</span>
                  <span>미가입 지역</span>
                </div>
              )}
            </div>
            <div className="map-fix-right map-mode map-btn-left">
              <button
                type="button"
                className="btn-wide-view"
                onClick={() => history.push(RouterPath.fulldashboard)}
              >
                <i style={{ backgroundImage: `url(${images.max})` }} />
                전체보기
              </button>
              {mapMarkerDepth === 0 || mapMarkerDepth === 1 ? null : (
                // 시각화
                <div className="map-visual" style={{ lineHeight: '0px' }}>
                  <Dropdown
                    onSelect={e => {
                      const { mode } = Options.visual.find(v => v.value === e);
                      setSelectedVisual(e);
                      setSelectUnit();
                      setMapModeId(mode);
                    }}
                  >
                    <Dropdown.Toggle>시각화</Dropdown.Toggle>
                    <Dropdown.Menu>
                      {Options.visual.map((item, idx) => {
                        return (
                          <Dropdown.Item
                            key={`dropdown-item-${idx}`}
                            eventKey={item.value}
                            className={classNames({
                              active: selectedVisual === item.value,
                            })}
                            style={{ lineHeight: 'normal' }}
                          >
                            {item.label}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
              {/* 보행, 교툥수단 선택 */}
              {/* {console.log(mapModeId)} */}
              {mapModeId === 'HEATMAP' ? (
                <div className="direcion-choice">
                  <span className="direcion-span direction-choice-1">
                    <Form.Check
                      type="radio"
                      id="heatmap-type-a"
                      name="heatmap-check"
                      checked={heatmapType === 'type-a'}
                      onChange={() => {
                        setHeatmapType('type-a');
                      }}
                    />
                    <Form.Label htmlFor="heatmap-type-a">밀집도A</Form.Label>
                  </span>
                  {selectedTimeValue === 0 && (
                    <span className="direcion-span direction-choice-2">
                      <Form.Check
                        type="radio"
                        id="heatmap-type-b"
                        name="heatmap-check"
                        checked={heatmapType === 'type-b'}
                        onChange={() => {
                          setHeatmapType('type-b');
                        }}
                      />
                      <Form.Label htmlFor="heatmap-type-b">밀집도B</Form.Label>
                    </span>
                  )}
                </div>
              ) : null}
              {mapModeId === 'DIRECTION' ? (
                <div className="direcion-choice">
                  <span className="direcion-span direction-choice-1">
                    <Form.Check
                      type="radio"
                      id="walk-way"
                      name="way-check"
                      checked={directionType === 'blue'}
                      onChange={() => {
                        setDirectionType('blue');
                      }}
                    />
                    <Form.Label htmlFor="walk-way">보행</Form.Label>
                  </span>
                  <span className="direcion-span direction-choice-2">
                    <Form.Check
                      type="radio"
                      id="vehicle-way"
                      name="way-check"
                      checked={directionType === 'red'}
                      onChange={() => {
                        setDirectionType('red');
                      }}
                    />
                    <Form.Label htmlFor="vehicle-way">이동수단</Form.Label>
                  </span>
                </div>
              ) : null}

              {/* 보행, 이동수단 선택 */}
            </div>
            <div className="map-fix-right map-btn-right">
              {buttons.map((btn, idx) => {
                return (
                  <button
                    type="button"
                    key={`buttons-${idx}`}
                    className="btn-wide-view"
                    style={{
                      backgroundColor:
                        btn.typeId === mapTypeId ? '#0070d7' : 'white',
                      color: btn.typeId === mapTypeId ? 'white' : 'black',
                    }}
                    onClick={() => {
                      setMapTypeId(btn.typeId);
                    }}
                  >
                    {btn.text}
                  </button>
                );
              })}
            </div>
          </NaverMap>
        </MapDiv>

        {/* 좌측 */}
        <div className="map-aside">
          <section className="py-3">
            <h5 className="mb-0">
              창원시 {profileId !== 0 && selectedProfileTitle}
            </h5>
          </section>
          <section>
            <div className="flex-between mb-2">
              <h6 className="mb-0">유동인구 현황</h6>
              <CustomSelect
                options={Options.time}
                value={selectedTime}
                onChange={setSelectedTime}
                className="mw-auto"
              />
            </div>
            <strong className="fs-28">
              {/* {Utils.numberComma(asideData.floatPop)} */}
              {/* TODO :: Profile 뎁스 유동인구 현황 바인딩 처리 요망 */}
              {Utils.changeNumberComma(dashboardValues.total?.count ?? 0)}
              <span className="ps-1">명</span>
            </strong>
            {/* TODO :: 향후 유동 상황판 API 전일 동시간대 정보 요청 요망 */}
            {/* <p className="updown">
              전일 동시간대 증감{' '}
              <small
                className={classNames({
                  up: asideData.incDcrSmTmPrvDay > 0,
                  dw: asideData.incDcrSmTmPrvDay < 0,
                })}
              >
                {asideData.incDcrSmTmPrvDay !== 0 &&
                  (asideData.incDcrSmTmPrvDay > 0 ? (
                    <i className="material-icons">arrow_drop_up</i>
                  ) : (
                    <i className="material-icons">arrow_drop_down</i>
                  ))}
                {Utils.numberComma(asideData.incDcrSmTmPrvDay)}
              </small>
            </p> */}
          </section>
          <section>
            <h6>금일 유동인구 누계</h6>
            <div className="chart-area">
              {/* <Chart data={totalFloatPopToday} /> */}
              <Chart data={chartData} />
            </div>
          </section>
          <section>
            <h6>방문객 수 누계</h6>
            <strong>
              {/* {Utils.numberComma(asideData.visitors)} */}
              {/* mapMarkerDepth */}
              {/* batch_visitors_totalDTO */}
              {Utils.changeNumberComma(dashboardValues.visitors?.count ?? 0)}
              {/* {mapMarkerDepth !== 2
                ? Utils.numberComma(
                    dashboardStateTotal.batch_visitors_totalDTO?.count ?? 0,
                  )
                : Utils.numberComma(
                    dashboardStateTotal.batch_visitors_profileDTO?.count ?? 0,
                  )} */}
            </strong>
          </section>
          <section>
            <h6>구축 장비 현황</h6>
            <div className="dl-box column">
              {/* getFloatingAPC 호출 오류 :: 오류 해결 후 디폴트 값 0으로 수정 요망 */}
              <dl>
                <dt>전체</dt>
                <dd>
                  <strong>
                    {/* {Utils.numberComma(
                      asideData.builtSnsr.normal + asideData.builtSnsr.abnormal,
                    )} */}
                    {Utils.changeNumberComma(dashboardValues.apc?.total ?? 0)}
                  </strong>
                </dd>
              </dl>
              <dl>
                <dt>정상</dt>
                <dd>
                  <strong>
                    {Utils.changeNumberComma(dashboardValues.apc?.normal ?? 0)}
                  </strong>
                </dd>
              </dl>
              <dl>
                <dt>장애</dt>
                <dd>
                  <strong>
                    {Utils.changeNumberComma(
                      dashboardValues.apc?.abnormal ?? 0,
                    )}
                  </strong>
                </dd>
              </dl>
            </div>
          </section>
        </div>
      </Container>
      {/* <CustomModal
        title="가입 지역 리스트"
        dialogClassName="modal-right"
        id="modal-map-list"
        show={profileListShow}
        onHide={() => setProfileListShow(false)}
      >
        <Modal.Body>
          <ToggleButtonGroup
            type="radio"
            name="sbsc-item"
            defaultValue="all"
            value={profileId}
            onChange={val => setProfileId(val)}
          >
            {apList.length > 0 &&
              apList.map((item, idx) => {
                return (
                  <ToggleButton
                    key={`sbsc-${item.value}`}
                    id={`sbsc-${item.value}`}
                    value={item.value}
                    variant="list"
                  >
                    <h6>{item.text}</h6>
                    <strong>{Utils.numberComma(item.visitor)}</strong>
                  </ToggleButton>
                );
              })}
          </ToggleButtonGroup>
        </Modal.Body>
      </CustomModal> */}

      <CustomModal
        title={`${selectUnit?.ap_nm || ''} 유동인구`}
        show={floatPopShow}
        onHide={() => setFloatPopShow(false)}
      >
        <Modal.Body>
          <div className="chart-area">
            <Chart data={chartDataAP} />
          </div>
        </Modal.Body>
      </CustomModal>
    </main>
  );
});

// 가입/미가입 지역 원마커 위경도
const tempPolygonCenter = [
  {
    lat: 35.3278241,
    lng: 128.5695364,
  },
];

function UnitMarker({ data, content, onClick }) {
  const navermaps = useNavermaps();
  return (
    <Marker
      position={new navermaps.LatLng(data.latitude, data.longitude)}
      icon={{
        content,
        origin: new navermaps.Point(0, 0),
        // anchor: new navermaps.Point(11, 35),
        anchor: new navermaps.Point(40, 58),
      }}
      onClick={() => onClick(data)}
    />
  );
}

function UnitArrowMarker({ data, content, onClick, clickable }) {
  const navermaps = useNavermaps();
  return (
    <Marker
      position={new navermaps.LatLng(data.latitude, data.longitude)}
      icon={{
        content,
        origin: new navermaps.Point(0, 0),
        // anchor: new navermaps.Point(120, 50),
        anchor: new navermaps.Point(-25, 50),
      }}
      onClick={() => onClick(data)}
      clickable={clickable}
    />
  );
}
