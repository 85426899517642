/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/named */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import { Container, Form, Button, Table, ListGroup } from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomSwal,
} from '@components';
import Moment from 'react-moment';
import { Utils, CHART_COLORS } from '@common';
import { images } from '@assets';
import { format } from 'date-fns';
import html2canvas from 'html2canvas';
import { VerticalBarChart, dataObj as barData } from './VerticalBarChart';
import { PoPLineChart, dataObj as popLineData } from './PoPLineChart';
import { TopDataPieChart, dataObj as pieData } from './TopdataPieChart';
import PdfDownloader from '../../common/PdfDownloader';

// 스피너
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

// 엑셀 다운
import { saveAs } from 'file-saver';
import * as ExcelJS from 'exceljs';

import './assets/css/ap-data-table.css';
import { getFlowDataLab, getFlowDataLabExcel } from '../../common/crud';

export default React.memo(function TopData(props) {
  const [bsunitOptions, setBsUnitOptions] = useState(businessOptions[0]); // 사업단위
  const [topNUnitOptions, setTopNUnitOptions] = useState(topNOptions[0]); // 순위
  const [citydoOptions, setCityDoOptions] = useState(cityOptions[0]); // 시/도
  const [regionOptions, setRegionOptions] = useState(GuOptions[0]); // 시/군/구
  const [provinceOptions, setProvinceOptions] = useState(); // 읍/면/동
  // const [serchfilter, setSerchFilter] = useState(); // 기간
  const [openDate, setOpenDate] = useState(
    new Date().setDate(new Date().getDate() - 30),
  ); // 일정
  const [closeDate, setCloseDate] = useState(new Date()); // 일정
  // 접속자수 순위(테이블)
  const [sumTotal, setSumTotal] = useState(0);
  const [topDataTop4Data, setTopDataTop4Data] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [barChartData, setBarChartData] = useState({
    labels: [],
    datasets: [{}],
  });
  const [popLineChartData, setPopLineChartData] = useState({});
  const [pieChartData, setPieChartData] = useState({
    labels: [],
    datasets: [{}],
  });

  const [labels, setLabels] = useState([
    { label: [], color: 'first' },
    { label: [], color: 'second' },
    { label: [], color: 'third' },
    { label: [], color: 'fourth' },
    { label: [], color: 'fifth' },
    { label: [], color: 'sixth' },
    { label: [], color: 'seventh' },
    { label: [], color: 'eighth' },
    { label: [], color: 'etc' },
  ]);

  const pdfRef1p = useRef();

  const tempCallAPI = async (startDate, endDate, profileId, topN) => {
    setIsLoading(true);

    try {
      const url = 'equip';
      const params = { startDate, endDate, profileId, topN };
      const response = (await getFlowDataLab(url, params)).data;

      // console.log(
      //   'startDate, endDate, profileId, topN : ',
      //   startDate,
      //   endDate,
      //   profileId,
      //   topN,
      // );

      // 유동인구 상위 지역의 데이터 사용량 조회(팝라인)
      const { chart, top4Data } = response.data;

      const tempResult = chart.reduce((acc, obj) => {
        const { apmac_id: apmacId, count, ap_nm: apNm } = obj;
        if (!acc[apmacId]) {
          acc[apmacId] = {
            apmacId,
            apNm,
            counts: [],
          };
        }
        acc[apmacId].counts.push(count);
        return acc;
      }, {});

      const sortedCountsArray = top4Data
        .filter(v => tempResult[v.apmac_id] !== undefined)
        .map(v => tempResult[v.apmac_id]);

      const popLineDataResult = {
        labels: popLineData.labels,
        datasets: sortedCountsArray.map((el, i) => {
          const colorIndex = i % CHART_COLORS.length;
          return {
            ...popLineData.datasets[i % popLineData.datasets.length], // Ensure we do not exceed original datasets length
            label: el.apNm,
            data: el.counts,
            borderColor: CHART_COLORS[colorIndex].borderColor,
            backgroundColor: CHART_COLORS[colorIndex].backgroundColor,
          };
        }),
      };

      setPopLineChartData(popLineDataResult);

      // 유동인구 접속자수 순위(테이블)
      setTopDataTop4Data(top4Data);

      const colorArr = [
        'first',
        'second',
        'third',
        'fourth',
        'fifth',
        'sixth',
        'seventh',
        'eighth',
        'etc',
      ];

      const tempLabelsObj = {
        first: [],
        second: [],
        third: [],
        fourth: [],
        fifth: [],
        sixth: [],
        seventh: [],
        eighth: [],
        etc: [],
      };

      const tempLabels = top4Data.map(el => el.ap_nm);

      tempLabels.forEach((label, index) => {
        const colorKey = colorArr[index % colorArr.length];
        tempLabelsObj[colorKey].push(label);
      });

      const initialLabels = colorArr.map(color => ({ label: [], color }));

      tempLabels.forEach((label, index) => {
        const colorIndex = index % colorArr.length;
        initialLabels[colorIndex].label.push(label);
      });

      setLabels(initialLabels);

      // console.log('initialLabels : ', initialLabels);

      // 유동인구 접속자수 순위(바)
      const top4DataArr = top4Data.map(el => el.count);

      const tempBarChartData = {
        labels: tempLabels,
        datasets: [
          {
            data: top4DataArr,
            backgroundColor: barData.datasets[0].backgroundColor,
            categoryPercentage: 1.0,
            barPercentage: 0.5,
          },
        ],
      };

      setBarChartData(tempBarChartData);

      const tempSumTotal = top4DataArr.reduce((a, b) => a + b, 0);
      setSumTotal(tempSumTotal);

      // 유동인구 접속자수 상위지역 비율(도넛)
      const tempPieChartData = {
        labels: tempLabels,
        datasets: [
          {
            data: top4DataArr,
            backgroundColor: pieData.datasets[0].backgroundColor,
            borderWidth: 1,
          },
        ],
        sum: tempSumTotal,
      };
      setPieChartData(tempPieChartData);
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  const createPdfIamge = async () => {
    setIsLoading(true);
    const tempPdfImages = [];
    const chartCanvas1p = await html2canvas(pdfRef1p.current);
    const chartImageUrl1p = chartCanvas1p.toDataURL('image/png');
    const chartPdfObj1p = {
      canvas: chartCanvas1p,
      image: chartImageUrl1p,
    };
    tempPdfImages.push(chartPdfObj1p);

    tempPdfImages.period = [openDate, closeDate];

    return tempPdfImages;
  };

  const downloadExcel = async () => {
    setIsLoading(true);
    // eslint-disable-next-line no-useless-catch
    try {
      const startDate = format(openDate, 'yyyy-MM-dd').toString();
      const endDate = format(closeDate, 'yyyy-MM-dd').toString();
      const profileId = bsunitOptions.value;

      const url = 'equip';
      const params = { startDate, endDate, profileId };
      const response = await getFlowDataLabExcel(url, params);
      const { data } = response.data;

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Datalab Topdata');

      worksheet.columns = [
        { header: '사업단위', key: 'profile', width: 10 },
        { header: 'AP', key: 'ap_nm', width: 30 },
        { header: 'AP MAC 주소', key: 'apmac_address', width: 17 },
        { header: 'AP 위치 주소', key: 'ap_address', width: 50 },
        { header: 'AP 위치 위도', key: 'ap_lat', width: 12 },
        { header: 'AP 위치 경도', key: 'ap_lon', width: 12 },
        { header: '접속자 수', key: 'count', width: 9 },
        { header: '접속 시간', key: 'datetime', width: 20 },
      ];

      worksheet.getCell('A1').value = '검색조건';
      worksheet.getCell('B1').value = `조회 시작일 : ${startDate}`;
      worksheet.getCell('B2').value = `조회 종료일 : ${endDate}`;
      worksheet.getCell('C1').value = '';
      worksheet.getCell('D1').value = '';
      worksheet.getCell('E1').value = '';
      worksheet.getCell('F1').value = '';
      worksheet.getCell('G1').value = '';
      worksheet.getCell('H1').value = '';

      worksheet.spliceRows(3, 0, ['']);

      worksheet.columns.forEach((col, index) => {
        worksheet.getCell(`${String.fromCharCode(index + 65)}4`).value =
          col.header;
      });

      worksheet.addConditionalFormatting({
        ref: 'A4:H4',
        rules: [
          {
            type: 'expression',
            formulae: ['MOD(ROW()+COLUMN(),1)=0'],
            style: {
              fill: {
                type: 'pattern',
                pattern: 'solid',
                bgColor: { argb: '5890c7' },
              },
            },
          },
        ],
      });

      data.forEach(v => {
        worksheet.addRow(v);
      });

      // 다운로드
      const mimeType = {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      };
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], mimeType);

      const fileName = `datalab_topdata_${format(
        openDate,
        'yyyyMMdd',
      ).toString()}_${format(closeDate, 'yyyyMMdd').toString()}.xlsx`;

      saveAs(blob, fileName);
    } catch (error) {
      throw error;
    }
  };

  const doSearch = () => {
    const startDate = format(openDate, 'yyyy-MM-dd').toString();
    const endDate = format(closeDate, 'yyyy-MM-dd').toString();
    tempCallAPI(startDate, endDate, bsunitOptions.value, topNUnitOptions.value);
  };

  useEffect(() => {
    doSearch();
  }, [bsunitOptions, topNUnitOptions, openDate, closeDate]);

  return (
    <main id="datalab-topdata">
      {isLoading && <Loading />}
      <Header title="접속자 수 통계" />
      <Container className="contents container-128">
        <article>
          <div className="title-datalab">
            <h5>사업단위별 상위지역 조회</h5>
          </div>
          <Form className="search-area">
            <div className="form-flex">
              <Form.Group className="form-group">
                <Form.Label>시/도</Form.Label>
                <CustomSelect
                  options={cityOptions}
                  value={citydoOptions}
                  onChange={setCityDoOptions}
                  className="mw-160"
                  placeholder="선택"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="ms-3">시/군/구</Form.Label>
                <CustomSelect
                  options={GuOptions}
                  value={regionOptions}
                  onChange={setRegionOptions}
                  className="mw-160"
                  placeholder="선택"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>읍/면/동</Form.Label>
                <CustomSelect
                  options={dongOptions}
                  value={provinceOptions}
                  onChange={setProvinceOptions}
                  isDisabled
                  className="mw-160"
                  placeholder="선택"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>사업단위</Form.Label>
                <CustomSelect
                  options={businessOptions}
                  value={bsunitOptions}
                  onChange={setBsUnitOptions}
                  className="mw-160"
                  placeholder="선택"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>순위</Form.Label>
                <CustomSelect
                  options={topNOptions}
                  value={topNUnitOptions}
                  onChange={setTopNUnitOptions}
                  className="mw-160"
                  placeholder="선택"
                />
              </Form.Group>
            </div>
            <Form.Group className="form-group">
              <Form.Label>기간</Form.Label>
              <div className="datepicker-wrap mw-280">
                <CustomDatePicker
                  value={openDate}
                  // minDate={Utils.getTomorrow()}
                  maxDate={closeDate || Utils.getToday()}
                  onChange={e => {
                    setOpenDate(e);
                  }}
                  placeholderText="yyyy.mm.dd"
                />
                <span>-</span>
                <CustomDatePicker
                  value={closeDate}
                  minDate={openDate || Utils.getToday()}
                  maxDate={Utils.getToday()}
                  onChange={setCloseDate}
                  placeholderText="yyyy.mm.dd"
                />
              </div>
              {/* <div className="ms-3">
                <Button onClick={() => doSearch()}>검색</Button>
              </div> */}
            </Form.Group>
          </Form>
        </article>
        <div className="btn-end">
          <Button
            onClick={() => {
              downloadExcel()
                .catch(() => {
                  CustomSwal.fire({
                    text: 'XLSX 다운로드 중 확인되지 않은 오류입니다. 잠시 후 다시 시도해주세요.',
                    confirmButtonText: '확인',
                  });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            <i
              style={{ backgroundImage: `url(${images.icExcel})` }}
              className="xlsx me-2"
            />
            XLSX
          </Button>
          <Button
            onClick={() => {
              createPdfIamge()
                .then(e => {
                  return PdfDownloader(e, e.period);
                })
                .catch(e => {
                  CustomSwal.fire({
                    text: 'PDF 다운로드 중 확인되지 않은 오류입니다. 잠시 후 다시 시도해주세요.',
                    confirmButtonText: '확인',
                  });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            <i className="material-icons me-2">picture_as_pdf</i>
            PDF
          </Button>
        </div>

        <div ref={pdfRef1p}>
          <article className="mt-4 scrollbar">
            <h5>접속자수 순위</h5>
            <Table className="table-hover text-start mt-3">
              <colgroup>
                <col width={80} />
                <col width={80} />
                <col width={120} />
                <col width={140} />
                <col width={140} />
                <col width={120} />
              </colgroup>
              <thead>
                <tr>
                  <th>Top</th>
                  <th>통신상태</th>
                  <th>AP</th>
                  <th>사업단위</th>
                  <th>기간</th>
                  <th className="td-right">접속자수</th>
                </tr>
              </thead>

              <tbody>
                {topDataTop4Data.length > 0 && (
                  <tr className="tr-sum">
                    <td>총계</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>
                      {openDate ? (
                        <Moment
                          date={openDate}
                          format="YYYY.MM.DD"
                          interval={0}
                        />
                      ) : (
                        '-'
                      )}
                      <span className="pd-lr"> - </span>
                      {closeDate ? (
                        <Moment
                          date={closeDate}
                          format="YYYY.MM.DD"
                          interval={0}
                        />
                      ) : (
                        '-'
                      )}
                    </td>
                    <td className="td-right">
                      {sumTotal && Utils.changeNumberComma(sumTotal)}
                    </td>
                  </tr>
                )}
                {topDataTop4Data.length > 0 ? (
                  topDataTop4Data.map((v, i) => {
                    return (
                      <tr key={`top4-${i}`}>
                        <td>{i + 1}</td>
                        <td>{v.device_state === 'Y' ? '원활' : '-'}</td>
                        <td>{v.ap_nm}</td>
                        <td>{v.profile_nm}</td>
                        <td>
                          {openDate ? (
                            <Moment
                              date={openDate}
                              format="YYYY.MM.DD"
                              interval={0}
                            />
                          ) : (
                            '-'
                          )}
                          <span className="pd-lr"> - </span>
                          {closeDate ? (
                            <Moment
                              date={closeDate}
                              format="YYYY.MM.DD"
                              interval={0}
                            />
                          ) : (
                            '-'
                          )}
                        </td>
                        <td className="td-right">
                          {v.count && Utils.changeNumberComma(v.count)}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="no-data" colSpan={6}>
                      요청 내역이 없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </article>
          <article className="mt-4">
            <div className="title-labels">
              {labels.map((option, index) => (
                <div key={`${option.label}-${index}`} className="oplabel-flex">
                  {/* <Form.Label>{option.label}</Form.Label> */}
                  <ListGroup>
                    {option.label.map((el, idx) => {
                      return (
                        <ListGroup.Item
                          key={`${index}-${idx}`}
                          className="list-group-item-custom"
                        >
                          <span className={`option-boll ${option.color}`} />
                          <span className="ms-2 item-text">{el}</span>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </div>
              ))}
            </div>
            <div className="grid-section">
              <div className="bar-section">
                <div className="flex-title">
                  <h5>접속자수 순위</h5>
                  <p>단위 : 천 명</p>
                </div>
                <div className="bar-height">
                  {topDataTop4Data.length > 0 ? (
                    <VerticalBarChart data={barChartData} />
                  ) : (
                    <div className="no-data" colSpan={6}>
                      요청 내역이 없습니다.
                    </div>
                  )}
                </div>
              </div>
              <div className="pie-section">
                <div className="flex-title">
                  <h5>접속자수 비율</h5>
                  <p>단위 : 천 명</p>
                </div>
                <div className="pie-height">
                  {topDataTop4Data.length > 0 ? (
                    <TopDataPieChart
                      // style={{ flex: 1, width: '100%' }}
                      data={pieChartData}
                      sumLabel="총 접속자 수"
                    />
                  ) : (
                    <div className="no-data" colSpan={6}>
                      요청 내역이 없습니다.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="line-section">
              <div className="flex-title">
                <h5>시간대별 접속자 수</h5>
                <p>
                  시작일 및 종료일 : {format(openDate, 'yyyy.MM.dd').toString()}{' '}
                  - {format(closeDate, 'yyyy.MM.dd').toString()}
                </p>
              </div>
              <div className="popline-box">
                {topDataTop4Data.length > 0 ? (
                  <PoPLineChart data={popLineChartData} />
                ) : (
                  <div className="no-data" colSpan={6}>
                    요청 내역이 없습니다.
                  </div>
                )}
              </div>
            </div>
          </article>
        </div>
      </Container>
    </main>
  );
});

function Loading() {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
      }}
    >
      <FontAwesomeIcon icon={faSpinner} spin size="7x" />
    </div>
  );
}

const businessOptions = [
  { label: '전체', value: 0 },
  { label: '창원_2차', value: 1 },
  { label: '창원_1차', value: 2 },
];
const topNOptions = [
  { label: '전체', value: 0 },
  { label: 'TOP 4', value: 4 },
  { label: 'TOP 10', value: 10 },
  { label: 'TOP 30', value: 30 },
  { label: 'TOP 50', value: 50 },
  { label: 'TOP 100', value: 100 },
];
const cityOptions = [{ label: '창원시', value: '1' }];
const GuOptions = [{ label: '의창구', value: '1' }];
const dongOptions = [];
