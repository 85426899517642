/* eslint-disable no-unused-expressions */
/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/button-has-type */
/* eslint-disable import/named */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';

// map
import ReactDOMServer from 'react-dom/server';
import {
  Container as MapDiv,
  Marker,
  NaverMap,
  useMap,
  useNavermaps,
} from 'react-naver-maps';

// 커스텀 마커 버튼
import '../Dashboard/assets/css/CustomMarker.css';

import { getFloating } from '@common/crud';

import {
  Container,
  Form,
  Button,
  InputGroup,
  Table,
  Modal,
} from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
  CustomPagination,
  CustomSwal,
} from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { images } from '@assets';
import axios from 'axios'; // ! 임시 API용
import { format } from 'date-fns';
import html2canvas from 'html2canvas';
import { PoPLineChart, dataObj as popLineData } from './PoPLineChart';

// 스피너
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

// 커스텀 마커 화살표
import './assets/css/arrow-three.css';
import ArrowThree from './ArrowThree';
import PdfDownloader from '../../common/PdfDownloader';

// 엑셀 다운
import { saveAs } from 'file-saver';
import * as ExcelJS from 'exceljs';

// PDF 다운
import { toPng } from 'html-to-image';
import ArrowThreeForList from './ArrowThreeForList';
import { getFlowDataLab, getFlowDataLabExcel } from '../../common/crud';

export default React.memo(function Population(props) {
  // const [prmOptions, setPrmOptions] = useState();
  const [bsunitOptions, setBsUnitOptions] = useState(businessOptions[0]); // 사업단위
  const [citydoOptions, setCityDoOptions] = useState(cityOptions[0]); // 시/도
  const [regionOptions, setRegionOptions] = useState(GuOptions[0]); // 시/군/구
  const [provinceOptions, setProvinceOptions] = useState(); // 읍/면/동
  // const [serchfilter, setSerchFilter] = useState(); // 기간
  const [openDate, setOpenDate] = useState(
    new Date().setDate(new Date().getDate() - 30),
  ); // 일정
  const [closeDate, setCloseDate] = useState(new Date()); // 일정

  // map
  const navermaps = useNavermaps();
  const [defaultCenter, setDefaultCenter] = useState();
  const [unitListAP, setUnitListAP] = useState([]);
  const [directDataState, setDirectDataState] = useState([]);
  const [popLineChartData, setPopLineChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [selectedAP, setSelectedAP] = useState(1);

  const [directionType, setDirectionType] = useState('blue');

  const [directLabels, setDirectLabels] = useState([
    { label: '', color: 'first', stroke: '#24427a' },
    { label: '', color: 'second', stroke: '#f99b2e' },
    { label: '', color: 'third', stroke: '#5890c7' },
    { label: '', color: 'fourth', stroke: '#624aef' },
    { label: '', color: 'fifth', stroke: '#2c8a3e' },
    { label: '', color: 'sixth', stroke: '#ff97d6' },
    { label: '', color: 'seventh', stroke: '#00deb5' },
    { label: '', color: 'eighth', stroke: '#eb440f' },
    { label: '', color: 'etc', stroke: '#bcbbdc' },
  ]);

  const pdfRef1p = useRef();
  const pdfRef2p = useRef();

  const [adminTableList, setAdminTableList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getPopulation = async () => {
    try {
      const url = 'state';
      const time = 0;
      const depth = 2;
      const id = bsunitOptions.value;
      const params = { time, depth, id };
      const response = (await getFloating(url, params)).data;
      const { batch_now_equipDTOList: batchNowEquipDTOList } = response.data;
      const { latitude, longitude } = batchNowEquipDTOList[0];
      setUnitListAP(batchNowEquipDTOList);
      setDefaultCenter(new navermaps.LatLng(latitude, longitude)); // 창원 위경도
    } catch (e) {
      console.error(e);
    }
  };

  const callApiDataLab = async (startDate, endDate, fromApId, type) => {
    try {
      // * 임시 API
      // axios.defaults.headers.common.Authorization = `Bearer eyJhbGciOiJIUzUxMiJ9.eyJhZG1JZCI6IjEiLCJleHAiOjE2ODg3NzM2NzksImlhdCI6MTY4MDEzMzY3OSwiand0VHlwZSI6ImFjY2VzcyJ9.puLU3MC53neo9AyqrXdai7TLHVYJfvqa-bgXtKtOH17gwieLtwSnFcsqe5_qaond7bRQyvGqSn_UqozUtOqEJg`;
      // const response = (
      //   await axios.get(
      //     `http://192.168.10.93:8081/api/v1/flowDataLab/direct?startDate=${startDate}&endDate=${endDate}&fromApId=${fromApId}`,
      //   )
      // ).data;

      const url = 'direct';
      const params = { startDate, endDate, fromApId, type };
      const response = (await getFlowDataLab(url, params)).data;

      const { directData } = response.data;

      let checkedDeviceStateYn = 'Y'; // 통신상태 확인
      const directDataResult = directData.reduce((acc, obj) => {
        const {
          fromAP_id: fromAPId,
          toAP_id: toAPId,
          fromAP_nm: fromAPNm,
          toAP_nm: toAPNm,
          device_state_yn: deviceStateYn,
          count,
          hh,
        } = obj;
        if (deviceStateYn !== 'Y') checkedDeviceStateYn = 'N';
        if (!acc[toAPId]) {
          acc[toAPId] = {
            fromAPId,
            fromAPNm,
            toAPId,
            toAPNm,
            deviceStateYn: checkedDeviceStateYn,
            counts: Array.from({ length: 24 }, _ => 0),
          };
        }
        acc[toAPId].counts[parseInt(hh, 10)] = count;
        return acc;
      }, {});
      const directDataResultArr = Object.values(directDataResult).map(v => v);
      setDirectDataState(directDataResultArr);

      console.log('directDataResultArr : ', directDataResultArr);

      // 유동인구 센서 위치 리스트
      const tempAdminTableList = directDataResultArr.map(obj => {
        const { fromAPId, fromAPNm, toAPId, toAPNm, deviceStateYn } = obj;

        // 방향값(transform rotate) 추가
        const { deg } =
          arrowRotateList
            .find(v => v.fromAPId === fromAPId)
            ?.rotate.find(v => v.toAPId === toAPId) || {};
        return {
          fromAPId,
          fromAPNm,
          toAPId,
          toAPNm,
          deviceStateYn,
          deg,
        };
      });
      setAdminTableList(tempAdminTableList);

      // 센서 위치에 따른 기간 조건별 유동인구수 조회(꺽은선 차트 레전드)
      const tempDirectLabelsArr = directLabels.map((obj, idx) => {
        const data = directDataResultArr[idx];
        return { ...obj, label: `${data?.fromAPNm} → ${data?.toAPNm}` };
      });
      setDirectLabels(tempDirectLabelsArr);

      // 센서 위치에 따른 기간 조건별 유동인구수 조회(꺽은선 차트)
      const countsArray = directDataResultArr.map(el => el.counts);
      const { labels: popLineLabels, datasets: popLineDatasets } = popLineData;
      const newDatasets = [];
      countsArray.forEach((v, i) =>
        newDatasets.push({ ...popLineDatasets[i], data: v }),
      );
      const popLineDataResult = {
        labels: popLineLabels,
        datasets: newDatasets,
      };
      setPopLineChartData(popLineDataResult);
    } catch (e) {
      console.error(e);
    }
  };

  function doSearch(apmacId) {
    const startDate = format(openDate, 'yyyy-MM-dd').toString();
    const endDate = format(closeDate, 'yyyy-MM-dd').toString();
    let type = 0;
    if (directionType !== 'blue') {
      type = 1;
    }
    callApiDataLab(startDate, endDate, apmacId, type); // API 호출
  }

  useEffect(() => {
    setDefaultCenter(new navermaps.LatLng(35.2538433, 128.6402609)); // 창원 위경도
  }, []);

  useEffect(() => {
    getPopulation('state', {
      time: 0,
      depth: 2,
      id: bsunitOptions.value,
    });
    bsunitOptions.value === 2 && setSelectedAP(9);
    bsunitOptions.value === 1 && setSelectedAP(1);
  }, [bsunitOptions]);

  useEffect(() => {
    doSearch(selectedAP);
  }, [selectedAP, openDate, closeDate, directionType]);

  const createPdfIamge = async () => {
    setIsLoading(true);
    const tempPdfImages = [];
    const chartCanvas1p = await html2canvas(pdfRef1p.current);
    // 지도 영역 표현되지 않는 문제 해결 위해 html-to-image 라이브러리로 대체
    // const chartImageUrl = chartCanvas.toDataURL('image/png');
    const chartImageUrl1p = await toPng(pdfRef1p.current, { cacheBust: true });
    const chartPdfObj1p = {
      canvas: chartCanvas1p,
      image: chartImageUrl1p,
    };
    const chartCanvas2p = await html2canvas(pdfRef2p.current);
    const chartImageUrl2p = await toPng(pdfRef2p.current, { cacheBust: true });
    const chartPdfObj2p = {
      canvas: chartCanvas2p,
      image: chartImageUrl2p,
    };
    tempPdfImages.push(chartPdfObj1p);
    tempPdfImages.push(chartPdfObj2p);
    return tempPdfImages;
  };

  // 엑셀 다운로드
  const downloadExcel = async () => {
    setIsLoading(true);
    // eslint-disable-next-line no-useless-catch
    try {
      const startDate = format(openDate, 'yyyy-MM-dd').toString();
      const endDate = format(closeDate, 'yyyy-MM-dd').toString();
      const fromApId = selectedAP;
      const type = directionType === 'blue' ? 0 : 1;

      const url = 'direct';
      const params = { startDate, endDate, fromApId, type };
      const response = await getFlowDataLabExcel(url, params);

      const { data } = response.data;

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Datalab Direct');

      // sheet 데이터 설정
      worksheet.columns = [
        { header: 'AP(출발)', key: 'fromAP_nm', width: 30 },
        { header: 'AP 위치 위도(출발)', key: 'fromAP_lat', width: 23 },
        { header: 'AP 위치 경도(출발)', key: 'fromAP_lon', width: 23 },
        { header: 'AP(도착)', key: 'toAP_nm', width: 30 },
        { header: 'AP 위치 위도(도착)', key: 'toAP_lat', width: 23 },
        { header: 'AP 위치 경도(도착)', key: 'toAP_lon', width: 23 },
        { header: '접속자 수', key: 'count', width: 9 },
        { header: '접속 시간', key: 'datetime', width: 20 },
      ];

      worksheet.getCell('C1').value = '';
      worksheet.getCell('D1').value = '';
      worksheet.getCell('E1').value = '';
      worksheet.getCell('F1').value = '';
      worksheet.getCell('G1').value = '';
      worksheet.getCell('H1').value = '';
      worksheet.getCell('A1').value = '검색조건';
      worksheet.getCell('B1').value = `조회 시작일 : ${startDate}`;
      worksheet.getCell('B2').value = `조회 종료일 : ${endDate}`;
      worksheet.getCell('B3').value = `보행/이동수단 : ${
        type === 0 ? '보행' : '이동수단'
      }`;

      worksheet.spliceRows(5, 0, ['']);

      worksheet.columns.forEach((col, index) => {
        worksheet.getCell(`${String.fromCharCode(index + 65)}5`).value =
          col.header;
      });

      worksheet.addConditionalFormatting({
        ref: 'A5:H5',
        rules: [
          {
            type: 'expression',
            formulae: ['MOD(ROW()+COLUMN(),1)=0'],
            style: {
              fill: {
                type: 'pattern',
                pattern: 'solid',
                bgColor: { argb: '5890c7' },
              },
            },
          },
        ],
      });

      data.forEach(v => {
        worksheet.addRow(v);
      });

      // 다운로드
      const mimeType = {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      };
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], mimeType);

      const fileName = `datalab_direct_${format(
        openDate,
        'yyyyMMdd',
      ).toString()}_${format(closeDate, 'yyyyMMdd').toString()}.xlsx`;

      saveAs(blob, fileName);
    } catch (error) {
      throw error;
    }
  };

  return (
    <main id="datalab-pop">
      {isLoading && <Loading />}
      <Header title="유동인구 분석 통계" />
      <Container className="contents container-110">
        <article>
          <div className="title-datalab">
            <h5>사업단위별 상위지역 조회</h5>
          </div>
          <Form className="search-area">
            <div className="form-flex">
              <Form.Group className="form-group">
                <Form.Label>시/도</Form.Label>
                <CustomSelect
                  options={cityOptions}
                  value={citydoOptions}
                  onChange={setCityDoOptions}
                  className="mw-160"
                  placeholder="선택"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="ms-3">시/군/구</Form.Label>
                <CustomSelect
                  options={GuOptions}
                  value={regionOptions}
                  onChange={setRegionOptions}
                  className="mw-160"
                  placeholder="선택"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>읍/면/동</Form.Label>
                <CustomSelect
                  options={dongOptions}
                  value={provinceOptions}
                  onChange={setProvinceOptions}
                  isDisabled
                  className="mw-160"
                  placeholder="선택"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>사업단위</Form.Label>
                <CustomSelect
                  options={businessOptions}
                  value={bsunitOptions}
                  onChange={setBsUnitOptions}
                  className="mw-160"
                  placeholder="선택"
                />
              </Form.Group>
              <div
                className="direction-choice"
                style={{ display: 'flex', gap: '16px' }}
              >
                <span
                  className="direcion-span direction-choice-1"
                  style={{ display: 'flex', gap: '4px' }}
                >
                  <Form.Check
                    type="radio"
                    id="walk-way"
                    name="way-check"
                    checked={directionType === 'blue'}
                    onChange={() => {
                      setDirectionType('blue');
                    }}
                  />
                  <Form.Label htmlFor="walk-way">보행</Form.Label>
                </span>
                <span
                  className="direcion-span direction-choice-2"
                  style={{ display: 'flex', gap: '4px' }}
                >
                  <Form.Check
                    type="radio"
                    id="vehicle-way"
                    name="way-check"
                    checked={directionType === 'red'}
                    onChange={() => {
                      setDirectionType('red');
                    }}
                  />
                  <Form.Label htmlFor="vehicle-way">이동수단</Form.Label>
                </span>
              </div>
            </div>
          </Form>
        </article>
        <div className="btn-end">
          <Button
            onClick={() => {
              downloadExcel()
                .catch(() => {
                  CustomSwal.fire({
                    text: 'XLSX 다운로드 중 확인되지 않은 오류입니다. 잠시 후 다시 시도해주세요.',
                    confirmButtonText: '확인',
                  });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            <i
              style={{ backgroundImage: `url(${images.icExcel})` }}
              className="xlsx me-2"
            />
            XLSX
          </Button>
          <Button
            onClick={() => {
              createPdfIamge()
                .then(e => {
                  return PdfDownloader(e);
                })
                .catch(e => {
                  CustomSwal.fire({
                    text: 'PDF 다운로드 중 확인되지 않은 오류입니다. 잠시 후 다시 시도해주세요.',
                    confirmButtonText: '확인',
                  });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            <i className="material-icons me-2">picture_as_pdf</i>
            PDF
          </Button>
        </div>
        <div ref={pdfRef1p}>
          <article className="mt-4 scrollbar" style={{ overflow: 'hidden' }}>
            <h5>유동인구 센서 위치 리스트</h5>
            <Table className="table-hover text-start mt-3">
              <colgroup>
                <col width={50} />
                <col width={50} />
                <col width={120} />
                <col width={120} />
                <col width={120} />
                <col width={120} />
                <col width={120} />
                <col width={120} />
                <col width={120} />
              </colgroup>
              <thead>
                <tr>
                  <th>No</th>
                  <th>통신상태</th>
                  <th>센서 시작 위치</th>
                  <th>구분</th>
                  <th>센서 종료 위치</th>
                  <th>시/도</th>
                  <th>시/군/구</th>
                  <th>읍/면/동</th>
                  <th>사업단위</th>
                </tr>
              </thead>

              <tbody>
                {adminTableList.length > 0 ? (
                  adminTableList.map((v, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{v.deviceStateYn === 'Y' ? '원활' : '장애'}</td>
                        <td>{v.fromAPNm}</td>
                        <td>
                          <ArrowThreeForList
                            stroke={`${directLabels[i].stroke}`}
                          />
                        </td>
                        <td>{v.toAPNm}</td>
                        <td>{citydoOptions.label}</td>
                        <td>{regionOptions.label}</td>
                        <td>{v.dong === undefined ? '-' : v.dong}</td>
                        <td>
                          {bsunitOptions === undefined
                            ? '-'
                            : bsunitOptions.label}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="no-data" colSpan={8}>
                      요청 내역이 없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </article>
          <article className="mt-4">
            <section className="map-div">
              <div className="flex-title">
                <h5>유동인구 센서 지도 위치</h5>
              </div>
              <MapDiv
                className="map-box"
                style={{
                  width: '100%',
                  height: '700px',
                }}
              >
                {() => {
                  const handleZoomChanged = useCallback(zoom => {
                    console.log(`zoom: ${zoom}`);
                  }, []);

                  return (
                    <NaverMap
                      defaultCenter={defaultCenter}
                      // 관성 드래깅 옵션
                      disableKineticPan={false}
                      zoomControl
                      zoomControlOptions={{
                        position: navermaps.Position.TOP_RIGHT,
                      }}
                      defaultZoom={18}
                      onZoomChanged={handleZoomChanged}
                      minZoom={16}
                      maxZoom={20}
                    >
                      {unitListAP.map((v, i) => {
                        const contentMarker = ReactDOMServer.renderToString(
                          <div
                            // className="custom-marker"
                            // style={{ display: 'grid', paddingTop: '10px' }}
                            className={`custom-marker ${
                              directionType === 'red' ? 'pink' : null
                            }`}
                            style={
                              !(directionType !== 'blue')
                                ? {
                                    // background:
                                    //   'linear-gradient(to bottom, #2787cc, #195078)',
                                    background: '#142286',
                                    display: 'grid',
                                    paddingTop: '10px',
                                  }
                                : {
                                    // background:
                                    //   'linear-gradient(to bottom, #F85F8B, #FF2564)',
                                    background: '#f6960a',
                                    display: 'grid',
                                    paddingTop: '10px',
                                  }
                            }
                          >
                            <div className="custom-marker-title">{v.ap_nm}</div>
                            <div
                              className="custom-marker-icon"
                              style={{ marginTop: '7px' }}
                            >
                              {/* <svg
                                width="13"
                                height="22"
                                viewBox="0 0 13 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.12 9.8H13V8H9.38L7.38 4.67C7.08 4.17 6.54 3.83 5.92 3.83C5.74 3.83 5.58 3.86 5.42 3.91L0 5.6V10.8H1.8V7.13L3.91 6.47L0 21.8H1.8L4.67 13.69L7 16.8V21.8H8.8V15.39L6.31 10.85L7.04 7.98M8 3.6C9 3.6 9.8 2.8 9.8 1.8C9.8 0.8 9 0 8 0C7 0 6.2 0.8 6.2 1.8C6.2 2.8 7 3.6 8 3.6Z"
                                  fill="white"
                                />
                              </svg> */}
                              {directionType === 'blue' ? (
                                <svg
                                  width="13"
                                  height="22"
                                  viewBox="0 0 13 22"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.12 9.8H13V8H9.38L7.38 4.67C7.08 4.17 6.54 3.83 5.92 3.83C5.74 3.83 5.58 3.86 5.42 3.91L0 5.6V10.8H1.8V7.13L3.91 6.47L0 21.8H1.8L4.67 13.69L7 16.8V21.8H8.8V15.39L6.31 10.85L7.04 7.98M8 3.6C9 3.6 9.8 2.8 9.8 1.8C9.8 0.8 9 0 8 0C7 0 6.2 0.8 6.2 1.8C6.2 2.8 7 3.6 8 3.6Z"
                                    fill="white"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="20"
                                  height="16"
                                  viewBox="0 0 20 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M1.92478 15.787H2.88321C3.48519 15.787 3.95252 15.3197 3.95252 14.7177V13.3474C5.71887 13.4583 8.32484 13.5454 10.4239 13.5454C12.5229 13.5454 15.1289 13.4662 16.8873 13.3474V14.7177C16.8873 15.3197 17.3546 15.787 17.9645 15.787H18.915C19.5249 15.787 19.9923 15.3197 19.9923 14.7177V10.0207C19.9923 8.61866 19.7309 7.84242 18.9942 6.884L18.2893 5.99686C17.9962 4.57903 17.4734 3.08199 17.1962 2.49584C16.7526 1.55326 15.8972 0.998801 14.8041 0.848305C14.2259 0.769096 12.5308 0.729492 10.4239 0.729492C8.31692 0.729492 6.61393 0.777017 6.04363 0.848305C4.95055 0.98296 4.08718 1.55326 3.65153 2.49584C3.3743 3.08199 2.85152 4.57903 2.55845 5.99686L1.8535 6.884C1.10894 7.84242 0.855469 8.61866 0.855469 10.0207V14.7177C0.855469 15.3197 1.3228 15.787 1.92478 15.787ZM4.33272 5.26814C4.5149 4.48398 4.86342 3.43842 5.10105 3.01862C5.33075 2.59881 5.59214 2.42456 6.08323 2.36119C6.64562 2.28198 8.00008 2.23445 10.4239 2.23445C12.8397 2.23445 14.2021 2.26614 14.7645 2.36119C15.2556 2.43248 15.5091 2.59881 15.7467 3.01862C15.9922 3.4305 16.317 4.48398 16.5071 5.26814C16.5942 5.60874 16.4437 5.76716 16.0873 5.75131C14.8675 5.68003 13.3308 5.60082 10.4239 5.60082C7.51691 5.60082 5.98026 5.68003 4.76045 5.75131C4.39609 5.76716 4.26144 5.60874 4.33272 5.26814ZM4.61788 11.3276C3.86539 11.3276 3.28717 10.7573 3.28717 10.0048C3.28717 9.24441 3.86539 8.67411 4.61788 8.67411C5.37036 8.67411 5.94066 9.24441 5.94066 10.0048C5.94066 10.7573 5.37036 11.3276 4.61788 11.3276ZM16.2299 11.3276C15.4694 11.3276 14.8991 10.7573 14.8991 10.0048C14.8991 9.24441 15.4694 8.67411 16.2299 8.67411C16.9823 8.67411 17.5526 9.24441 17.5526 10.0048C17.5526 10.7573 16.9823 11.3276 16.2299 11.3276ZM8.28523 10.9949C7.71493 10.9949 7.31889 10.5989 7.31889 10.0365C7.31889 9.47412 7.71493 9.07807 8.28523 9.07807H12.5625C13.1249 9.07807 13.5209 9.47412 13.5209 10.0365C13.5209 10.5989 13.1249 10.9949 12.5625 10.9949H8.28523Z"
                                    fill="#002060"
                                  />
                                </svg>
                              )}
                            </div>
                            <div className="custom-marker-value">
                              <link
                                href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap"
                                rel="stylesheet"
                              />
                              {Utils.changeNumberComma(v.count)}
                            </div>
                          </div>,
                        );
                        // TODO: 기본값으로 선택된 IP에 대한 화살표 표시 처리 요망
                        return (
                          <>
                            {selectedAP === v.apmac_id &&
                              adminTableList.map((toAp, toApIdx) => {
                                const { deg } = toAp;
                                return (
                                  <UnitArrowMarker
                                    key={toApIdx}
                                    data={v}
                                    content={ReactDOMServer.renderToString(
                                      <ArrowThree
                                        transform={`rotate(${deg}deg)`}
                                        color={directLabels[toApIdx].color}
                                      />,
                                    )}
                                    clickable={false}
                                  />
                                );
                              })}
                            <UnitMarker
                              key={i}
                              data={v}
                              content={contentMarker}
                              onClick={e => {
                                setSelectedAP(e.apmac_id);
                                setDefaultCenter(
                                  new navermaps.LatLng(v.latitude, v.longitude),
                                );
                              }}
                            />
                          </>
                        );
                      })}
                    </NaverMap>
                  );
                }}
              </MapDiv>
            </section>
            <div className="line-section">
              <div className="flex-title">
                <h5>센서 위치에 따른 기간 조건별 유동인구수 조회</h5>
                <p>단위 : 천 명</p>
              </div>
              <Form className="search-area mb-4">
                <Form.Group className="form-group">
                  <Form.Label>기간</Form.Label>
                  {/* <CustomSelect
                  options={dateday}
                  value={serchfilter}
                  onChange={setSerchFilter}
                  className="mw-200"
                  placeholder="선택"
                /> */}
                  {/* <div className="datepicker-wrap mw-350 ms-3"> */}
                  <div className="datepicker-wrap mw-350">
                    <CustomDatePicker
                      value={openDate}
                      // minDate={Utils.getTomorrow()}
                      // maxDate={closeDate}
                      maxDate={closeDate || Utils.getToday()}
                      onChange={e => {
                        setOpenDate(e);
                      }}
                      placeholderText="yyyy.mm.dd"
                    />
                    <span>-</span>
                    <CustomDatePicker
                      value={closeDate}
                      // minDate={openDate || Utils.getTomorrow()}
                      minDate={openDate || Utils.getToday()}
                      maxDate={Utils.getToday()}
                      onChange={setCloseDate}
                      placeholderText="yyyy.mm.dd"
                    />
                  </div>
                  {/* <Button className="ms-3" onClick={() => doSearch(selectedAP)}>
                    검색
                  </Button> */}
                </Form.Group>
              </Form>
              {popLineChartData.datasets.length !== 0 ? (
                <div>
                  <div className="popline-box">
                    <PoPLineChart data={popLineChartData} />
                  </div>
                  <div className="title-labels mt-3">
                    {popLineChartData.datasets.map((_, i) => (
                      <div className="oplabel-flex">
                        <span
                          className={`option-boll ${directLabels[i]?.color}`}
                        />
                        <Form.Label>{directLabels[i]?.label}</Form.Label>
                      </div>
                    ))}
                    {/* {directLabels.map(option => (
                      <div className="oplabel-flex">
                        <span className={`option-boll ${option.color}`} />
                        <Form.Label>{option.label}</Form.Label>
                      </div>
                    ))} */}
                  </div>
                </div>
              ) : (
                <div className="no-data">요청 내역이 없습니다.</div>
              )}
            </div>
          </article>
        </div>
        <div ref={pdfRef2p}>
          <article
            className="mt-4 scrollbar aptable"
            style={{ overflow: 'hidden' }}
          >
            <div className="flex-title">
              <h5>센서 위치별 시간대 유동인구수(오전)</h5>
              <p>단위 : 명 </p>
            </div>
            <Table className="table-hover text-start mt-3">
              <colgroup>
                <col width={50} />
                <col width={150} />
                <col width={150} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
              </colgroup>
              <thead>
                <tr>
                  <th rowSpan={2} className="bd-right">
                    No
                  </th>
                  <th rowSpan={2} className="bd-right">
                    센서 시작 위치
                  </th>
                  <th rowSpan={2} className="bd-right">
                    센서 종료 위치
                  </th>
                  <th colSpan={12} className="bd-bottom center-text">
                    시간대별 평균값
                  </th>
                  <th rowSpan={2} className="bd-left">
                    전체 평균량
                  </th>
                </tr>
                <tr>
                  <th className="bd-right center-text">00 ~ 01</th>
                  <th className="bd-right center-text">01 ~ 02</th>
                  <th className="bd-right center-text">02 ~ 03</th>
                  <th className="bd-right center-text">03 ~ 04</th>
                  <th className="bd-right center-text">04 ~ 05</th>
                  <th className="bd-right center-text">05 ~ 06</th>
                  <th className="bd-right center-text">06 ~ 07</th>
                  <th className="bd-right center-text">07 ~ 08</th>
                  <th className="bd-right center-text">08 ~ 09</th>
                  <th className="bd-right center-text">09 ~ 10</th>
                  <th className="bd-right center-text">10 ~ 11</th>
                  <th className="bd-right center-text">11 ~ 12</th>
                </tr>
              </thead>

              <tbody>
                {directDataState.length > 0 ? (
                  directDataState.map((v, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{v.fromAPNm}</td>
                        <td>{v.toAPNm}</td>
                        {v.counts.slice(0, 12).map((count, idx) => (
                          <td className="right-text">
                            {count && Utils.changeNumberComma(count)}
                          </td>
                        ))}
                        <td className="right-text">
                          {v.counts &&
                            Utils.changeNumberComma(
                              v.counts
                                .slice(0, 12)
                                .reduce((acc, curr) => acc + curr, 0) /
                                v.counts.slice(0, 12).length,
                            )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="no-data" colSpan={112}>
                      요청 내역이 없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </article>
          <article
            className="mt-4 scrollbar aptable"
            style={{ overflow: 'hidden' }}
          >
            <div className="flex-title">
              <h5>센서 위치별 시간대 유동인구수(오후)</h5>
              <p>단위 : 명 </p>
            </div>
            <Table className="table-hover text-start mt-3">
              <colgroup>
                <col width={50} />
                <col width={150} />
                <col width={150} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
              </colgroup>
              <thead>
                <tr>
                  <th rowSpan={2} className="bd-right">
                    No
                  </th>
                  <th rowSpan={2} className="bd-right">
                    센서 시작 위치
                  </th>
                  <th rowSpan={2} className="bd-right">
                    센서 종료 위치
                  </th>
                  <th colSpan={12} className="bd-bottom center-text">
                    시간대별 평균값
                  </th>
                  <th rowSpan={2} className="bd-left">
                    전체 평균량
                  </th>
                </tr>
                <tr>
                  <th className="bd-right center-text">12 ~ 13</th>
                  <th className="bd-right center-text">13 ~ 14</th>
                  <th className="bd-right center-text">14 ~ 15</th>
                  <th className="bd-right center-text">15 ~ 16</th>
                  <th className="bd-right center-text">16 ~ 17</th>
                  <th className="bd-right center-text">17 ~ 18</th>
                  <th className="bd-right center-text">18 ~ 19</th>
                  <th className="bd-right center-text">19 ~ 20</th>
                  <th className="bd-right center-text">20 ~ 21</th>
                  <th className="bd-right center-text">21 ~ 22</th>
                  <th className="bd-right center-text">22 ~ 23</th>
                  <th className="bd-right center-text">23 ~ 24</th>
                </tr>
              </thead>

              <tbody>
                {directDataState.length > 0 ? (
                  directDataState.map((v, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{v.fromAPNm}</td>
                        <td>{v.toAPNm}</td>
                        {v.counts.slice(12).map((count, idx) => (
                          <td className="right-text">
                            {count && Utils.changeNumberComma(count)}
                          </td>
                        ))}
                        <td className="right-text">
                          {v.counts &&
                            Utils.changeNumberComma(
                              v.counts
                                .slice(12)
                                .reduce((acc, curr) => acc + curr, 0) /
                                v.counts.slice(12).length,
                            )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="no-data" colSpan={112}>
                      요청 내역이 없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </article>
        </div>
      </Container>
    </main>
  );
});

function UnitMarker({ data, content, onClick }) {
  const navermaps = useNavermaps();
  return (
    <Marker
      position={new navermaps.LatLng(data.latitude, data.longitude)}
      icon={{
        content,
        origin: new navermaps.Point(0, 0),
        // anchor: new navermaps.Point(11, 35),
        anchor: new navermaps.Point(40, 58),
      }}
      onClick={() => onClick(data)}
    />
  );
}

function UnitArrowMarker({ data, content, onClick, clickable }) {
  const navermaps = useNavermaps();
  return (
    <Marker
      position={new navermaps.LatLng(data.latitude, data.longitude)}
      icon={{
        content,
        origin: new navermaps.Point(0, 0),
        // anchor: new navermaps.Point(120, 50),
        anchor: new navermaps.Point(130, 50),
      }}
      onClick={() => onClick(data)}
      clickable={clickable}
    />
  );
}

function Loading() {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
      }}
    >
      <FontAwesomeIcon icon={faSpinner} spin size="7x" />
    </div>
  );
}

const businessOptions = [
  { label: '창원_2차', value: 1 },
  { label: '창원_1차', value: 2 },
];
const cityOptions = [{ label: '창원시', value: '1' }];
const GuOptions = [{ label: '의창구', value: '1' }];
const dongOptions = [];
const dateday = [
  { label: '일별', value: '1' },
  { label: '월별', value: '2' },
  { label: '년별', value: '3' },
];
const arrowRotateList = [
  {
    fromAPId: 1,
    fromAPNm: '수소충전소앞',
    rotate: [
      {
        toAPId: 2,
        toAPNm: '산동지하차도 입구',
        deg: 325,
      },
      {
        toAPId: 3,
        toAPNm: '금속소재종합센터 앞',
        deg: 300,
      },
      {
        toAPId: 4,
        toAPNm: '유료주차장 입구',
        deg: 15,
      },
      {
        toAPId: 5,
        toAPNm: '지하차도 입구',
        deg: 40,
      },
      {
        toAPId: 6,
        toAPNm: '올리브영앞',
        deg: 110,
      },
      {
        toAPId: 7,
        toAPNm: '공원입구',
        deg: 155,
      },
    ],
  },
  {
    fromAPId: 2,
    fromAPNm: '산동지하차도 입구',
    rotate: [
      {
        toAPId: 1,
        toAPNm: '수소충전소앞',
        deg: 145,
      },
      {
        toAPId: 3,
        toAPNm: '금속소재종합센터 앞',
        deg: 245,
      },
      {
        toAPId: 4,
        toAPNm: '유료주차장 입구',
        deg: 70,
      },
      {
        toAPId: 5,
        toAPNm: '지하차도 입구',
        deg: 95,
      },
      {
        toAPId: 6,
        toAPNm: '올리브영앞',
        deg: 120,
      },
      {
        toAPId: 7,
        toAPNm: '공원입구',
        deg: 165,
      },
    ],
  },
  {
    fromAPId: 3,
    fromAPNm: '금속소재종합센터 앞',
    rotate: [
      {
        toAPId: 1,
        toAPNm: '수소충전소앞',
        deg: 135,
      },
      {
        toAPId: 2,
        toAPNm: '산동지하차도 입구',
        deg: 50,
      },
      {
        toAPId: 4,
        toAPNm: '유료주차장 입구',
        deg: 75,
      },
      {
        toAPId: 5,
        toAPNm: '지하차도 입구',
        deg: 95,
      },
      {
        toAPId: 6,
        toAPNm: '올리브영앞',
        deg: 120,
      },
      {
        toAPId: 7,
        toAPNm: '공원입구',
        deg: 150,
      },
    ],
  },
  {
    fromAPId: 4,
    fromAPNm: '유료주차장 입구',
    rotate: [
      {
        toAPId: 1,
        toAPNm: '수소충전소앞',
        deg: 200,
      },
      {
        toAPId: 2,
        toAPNm: '산동지하차도 입구',
        deg: 280,
      },
      {
        toAPId: 3,
        toAPNm: '금속소재종합센터 앞',
        deg: 260,
      },
      {
        toAPId: 5,
        toAPNm: '지하차도 입구',
        deg: 140,
      },
      {
        toAPId: 6,
        toAPNm: '올리브영앞',
        deg: 160,
      },
      {
        toAPId: 7,
        toAPNm: '공원입구',
        deg: 180,
      },
    ],
  },
  {
    fromAPId: 5,
    fromAPNm: '지하차도 입구',
    rotate: [
      {
        toAPId: 1,
        toAPNm: '수소충전소앞',
        deg: 230,
      },
      {
        toAPId: 2,
        toAPNm: '산동지하차도 입구',
        deg: 300,
      },
      {
        toAPId: 3,
        toAPNm: '금속소재종합센터 앞',
        deg: 270,
      },
      {
        toAPId: 4,
        toAPNm: '유료주차장 입구',
        deg: 330,
      },
      {
        toAPId: 6,
        toAPNm: '올리브영앞',
        deg: 150,
      },
      {
        toAPId: 7,
        toAPNm: '공원입구',
        deg: 195,
      },
    ],
  },
  {
    fromAPId: 6,
    fromAPNm: '올리브영앞',
    rotate: [
      {
        toAPId: 1,
        toAPNm: '수소충전소앞',
        deg: 285,
      },
      {
        toAPId: 2,
        toAPNm: '산동지하차도 입구',
        deg: 305,
      },
      {
        toAPId: 3,
        toAPNm: '금속소재종합센터 앞',
        deg: 295,
      },
      {
        toAPId: 4,
        toAPNm: '유료주차장 입구',
        deg: 310,
      },
      {
        toAPId: 5,
        toAPNm: '지하차도 입구',
        deg: 320,
      },
      {
        toAPId: 7,
        toAPNm: '공원입구',
        deg: 270,
      },
    ],
  },
  {
    fromAPId: 7,
    fromAPNm: '공원입구',
    rotate: [
      {
        toAPId: 1,
        toAPNm: '수소충전소앞',
        deg: 320,
      },
      {
        toAPId: 2,
        toAPNm: '산동지하차도 입구',
        deg: 335,
      },
      {
        toAPId: 3,
        toAPNm: '금속소재종합센터 앞',
        deg: 305,
      },
      {
        toAPId: 4,
        toAPNm: '유료주차장 입구',
        deg: 350,
      },
      {
        toAPId: 5,
        toAPNm: '지하차도 입구',
        deg: 15,
      },
      {
        toAPId: 6,
        toAPNm: '올리브영앞',
        deg: 100,
      },
    ],
  },
  {
    fromAPId: 9,
    fromAPNm: '아우내장터 앞',
    rotate: [
      {
        toAPId: 10,
        toAPNm: '명서동 503',
        deg: 170,
      },
      {
        toAPId: 11,
        toAPNm: '팔용동 517',
        deg: 250,
      },
      {
        toAPId: 12,
        toAPNm: '약국앞 ',
        deg: 115,
      },
      {
        toAPId: 13,
        toAPNm: 'CGV앞_3',
        deg: 335,
      },
      {
        toAPId: 14,
        toAPNm: 'GS칼텍스_3',
        deg: 50,
      },
      {
        toAPId: 15,
        toAPNm: '뚜레주르_2',
        deg: 25,
      },
      {
        toAPId: 16,
        toAPNm: '버스터미널(좌)_2',
        deg: 0,
      },
      {
        toAPId: 17,
        toAPNm: '버스터미널_2',
        deg: 15,
      },
      {
        toAPId: 18,
        toAPNm: '스타트업_1',
        deg: 35,
      },
    ],
  },
  {
    fromAPId: 10,
    fromAPNm: '명서동 503',
    rotate: [
      {
        toAPId: 9,
        toAPNm: '아우내장터 앞',
        deg: 330,
      },
      {
        toAPId: 11,
        toAPNm: '팔용동 517',
        deg: 300,
      },
      {
        toAPId: 12,
        toAPNm: '약국앞 ',
        deg: 35,
      },
      {
        toAPId: 13,
        toAPNm: 'CGV앞_3',
        deg: 340,
      },
      {
        toAPId: 14,
        toAPNm: 'GS칼텍스_3',
        deg: 25,
      },
      {
        toAPId: 15,
        toAPNm: '뚜레주르_2',
        deg: 10,
      },
      {
        toAPId: 16,
        toAPNm: '버스터미널(좌)_2',
        deg: 355,
      },
      {
        toAPId: 17,
        toAPNm: '버스터미널_2',
        deg: 5,
      },
      {
        toAPId: 18,
        toAPNm: '스타트업_1',
        deg: 15,
      },
    ],
  },
  {
    fromAPId: 11,
    fromAPNm: '팔용동 517',
    rotate: [
      {
        toAPId: 9,
        toAPNm: '아우내장터 앞',
        deg: 320,
      },
      {
        toAPId: 10,
        toAPNm: '명서동 503',
        deg: 320,
      },
      {
        toAPId: 12,
        toAPNm: '약국앞 ',
        deg: 305,
      },
      {
        toAPId: 13,
        toAPNm: 'CGV앞_3',
        deg: 350,
      },
      {
        toAPId: 14,
        toAPNm: 'GS칼텍스_3',
        deg: 15,
      },
      {
        toAPId: 15,
        toAPNm: '뚜레주르_2',
        deg: 100,
      },
      {
        toAPId: 16,
        toAPNm: '버스터미널(좌)_2',
        deg: 100,
      },
      {
        toAPId: 17,
        toAPNm: '버스터미널_2',
        deg: 100,
      },
      {
        toAPId: 18,
        toAPNm: '스타트업_1',
        deg: 100,
      },
    ],
  },
  {
    fromAPId: 12,
    fromAPNm: '약국앞 ',
    rotate: [
      {
        toAPId: 9,
        toAPNm: '아우내장터 앞',
        deg: 290,
      },
      {
        toAPId: 10,
        toAPNm: '명서동 503',
        deg: 235,
      },
      {
        toAPId: 11,
        toAPNm: '팔용동 517',
        deg: 275,
      },
      {
        toAPId: 13,
        toAPNm: 'CGV앞_3',
        deg: 310,
      },
      {
        toAPId: 14,
        toAPNm: 'GS칼텍스_3',
        deg: 25,
      },
      {
        toAPId: 15,
        toAPNm: '뚜레주르_2',
        deg: 335,
      },
      {
        toAPId: 16,
        toAPNm: '버스터미널(좌)_2',
        deg: 325,
      },
      {
        toAPId: 17,
        toAPNm: '버스터미널_2',
        deg: 345,
      },
      {
        toAPId: 18,
        toAPNm: '스타트업_1',
        deg: 10,
      },
    ],
  },
  {
    fromAPId: 13,
    fromAPNm: 'CGV앞_3',
    rotate: [
      {
        toAPId: 9,
        toAPNm: '아우내장터 앞',
        deg: 175,
      },
      {
        toAPId: 10,
        toAPNm: '명서동 503',
        deg: 160,
      },
      {
        toAPId: 11,
        toAPNm: '팔용동 517',
        deg: 235,
      },
      {
        toAPId: 12,
        toAPNm: '약국앞 ',
        deg: 130,
      },
      {
        toAPId: 14,
        toAPNm: 'GS칼텍스_3',
        deg: 50,
      },
      {
        toAPId: 15,
        toAPNm: '뚜레주르_2',
        deg: 65,
      },
      {
        toAPId: 16,
        toAPNm: '버스터미널(좌)_2',
        deg: 30,
      },
      {
        toAPId: 17,
        toAPNm: '버스터미널_2',
        deg: 20,
      },
      {
        toAPId: 18,
        toAPNm: '스타트업_1',
        deg: 40,
      },
    ],
  },
  {
    fromAPId: 14,
    fromAPNm: 'GS칼텍스_3',
    rotate: [
      {
        toAPId: 9,
        toAPNm: '아우내장터 앞',
        deg: 220,
      },
      {
        toAPId: 10,
        toAPNm: '명서동 503',
        deg: 210,
      },
      {
        toAPId: 11,
        toAPNm: '팔용동 517',
        deg: 270,
      },
      {
        toAPId: 12,
        toAPNm: '약국앞 ',
        deg: 200,
      },
      {
        toAPId: 13,
        toAPNm: 'CGV앞_3',
        deg: 240,
      },
      {
        toAPId: 15,
        toAPNm: '뚜레주르_2',
        deg: 230,
      },
      {
        toAPId: 16,
        toAPNm: '버스터미널(좌)_2',
        deg: 250,
      },
      {
        toAPId: 17,
        toAPNm: '버스터미널_2',
        deg: 260,
      },
      {
        toAPId: 18,
        toAPNm: '스타트업_1',
        deg: 280,
      },
    ],
  },
  {
    fromAPId: 15,
    fromAPNm: '뚜레주르_2',
    rotate: [
      {
        toAPId: 9,
        toAPNm: '아우내장터 앞',
        deg: 220,
      },
      {
        toAPId: 10,
        toAPNm: '명서동 503',
        deg: 190,
      },
      {
        toAPId: 11,
        toAPNm: '팔용동 517',
        deg: 240,
      },
      {
        toAPId: 12,
        toAPNm: '약국앞 ',
        deg: 150,
      },
      {
        toAPId: 13,
        toAPNm: 'CGV앞_3',
        deg: 260,
      },
      {
        toAPId: 14,
        toAPNm: 'GS칼텍스_3',
        deg: 45,
      },
      {
        toAPId: 16,
        toAPNm: '버스터미널(좌)_2',
        deg: 315,
      },
      {
        toAPId: 17,
        toAPNm: '버스터미널_2',
        deg: 355,
      },
      {
        toAPId: 18,
        toAPNm: '스타트업_1',
        deg: 20,
      },
    ],
  },
  {
    fromAPId: 16,
    fromAPNm: '버스터미널(좌)_2',
    rotate: [
      {
        toAPId: 9,
        toAPNm: '아우내장터 앞',
        deg: 200,
      },
      {
        toAPId: 10,
        toAPNm: '명서동 503',
        deg: 180,
      },
      {
        toAPId: 11,
        toAPNm: '팔용동 517',
        deg: 240,
      },
      {
        toAPId: 12,
        toAPNm: '약국앞 ',
        deg: 160,
      },
      {
        toAPId: 13,
        toAPNm: 'CGV앞_3',
        deg: 225,
      },
      {
        toAPId: 14,
        toAPNm: 'GS칼텍스_3',
        deg: 50,
      },
      {
        toAPId: 15,
        toAPNm: '뚜레주르_2',
        deg: 145,
      },
      {
        toAPId: 17,
        toAPNm: '버스터미널_2',
        deg: 20,
      },
      {
        toAPId: 18,
        toAPNm: '스타트업_1',
        deg: 35,
      },
    ],
  },
  {
    fromAPId: 17,
    fromAPNm: '버스터미널_2',
    rotate: [
      {
        toAPId: 9,
        toAPNm: '아우내장터 앞',
        deg: 200,
      },
      {
        toAPId: 10,
        toAPNm: '명서동 503',
        deg: 180,
      },
      {
        toAPId: 11,
        toAPNm: '팔용동 517',
        deg: 240,
      },
      {
        toAPId: 12,
        toAPNm: '약국앞 ',
        deg: 160,
      },
      {
        toAPId: 13,
        toAPNm: 'CGV앞_3',
        deg: 230,
      },
      {
        toAPId: 14,
        toAPNm: 'GS칼텍스_3',
        deg: 60,
      },
      {
        toAPId: 15,
        toAPNm: '뚜레주르_2',
        deg: 190,
      },
      {
        toAPId: 16,
        toAPNm: '버스터미널(좌)_2',
        deg: 215,
      },
      {
        toAPId: 18,
        toAPNm: '스타트업_1',
        deg: 40,
      },
    ],
  },
  {
    fromAPId: 18,
    fromAPNm: '스타트업_1',
    rotate: [
      {
        toAPId: 9,
        toAPNm: '아우내장터 앞',
        deg: 210,
      },
      {
        toAPId: 10,
        toAPNm: '명서동 503',
        deg: 200,
      },
      {
        toAPId: 11,
        toAPNm: '팔용동 517',
        deg: 260,
      },
      {
        toAPId: 12,
        toAPNm: '약국앞 ',
        deg: 190,
      },
      {
        toAPId: 13,
        toAPNm: 'CGV앞_3',
        deg: 230,
      },
      {
        toAPId: 14,
        toAPNm: 'GS칼텍스_3',
        deg: 75,
      },
      {
        toAPId: 15,
        toAPNm: '뚜레주르_2',
        deg: 220,
      },
      {
        toAPId: 16,
        toAPNm: '버스터미널(좌)_2',
        deg: 240,
      },
      {
        toAPId: 17,
        toAPNm: '버스터미널_2',
        deg: 250,
      },
    ],
  },
];
