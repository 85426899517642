import React from 'react';

function SensingAPInfo({ icon, text }) {
  return (
    <div className="sensing-icon-container">
      <i className="material-icons sensing-icon sensing-icon-el">{icon}</i>
      <span className="sensing-icon-info sensing-icon-el">{text}</span>
    </div>
  );
}

export default SensingAPInfo;
