/* eslint-disable import/order */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable new-cap */
import jsPDF from 'jspdf';
import font from './font/NanumGothic-normal';
import { format } from 'date-fns';

const PdfDownloader = async (images, period, orientation = 'p') => {
  const [openDate, closeDate] = period;

  const startDate = format(openDate, 'yyyy.MM.dd').toString();
  const endDate = format(closeDate, 'yyyy.MM.dd').toString();

  const periodStr = `${startDate} ~ ${endDate}`;

  try {
    const marginLeft = 10; // 왼쪽 마진 값 (mm)
    const marginRight = 10; // 오른쪽 마진 값 (mm)
    const marginTop = 15; // 상단 마진 값 (mm)
    const marginBottom = 10; // 하단 마진 값 (mm)
    const imageMargin = 5; // 이미지 사이의 여백 (mm)

    // PDF 문서 준비
    const doc = new jsPDF(orientation, 'mm', 'a4', true);

    // PDF 페이지의 가로 세로 사이즈
    const pageWidth =
      doc.internal.pageSize.getWidth() - (marginLeft + marginRight);
    const pageHeight =
      doc.internal.pageSize.getHeight() -
      (marginTop + marginBottom + imageMargin);

    // 한글 폰트 추가
    doc.addFileToVFS('NanumGothic.ttf', font);
    doc.addFont('NanumGothic.ttf', 'NanumGothic', 'normal');
    doc.setFont('NanumGothic');

    // "보고서" 문구 추가 (중앙 정렬)
    doc.setFontSize(24);
    const reportTextWidth =
      (doc.getStringUnitWidth('보  고  서') * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const reportTextX = (pageWidth - reportTextWidth) / 2 + marginLeft;
    doc.text('보  고  서', reportTextX, marginTop + 10);

    // 기간 정보 추가 (왼쪽 정렬)
    doc.setFontSize(10);
    const periodText = `◯ 기간: ${periodStr}`;
    doc.text(periodText, marginLeft, marginTop + 25);

    await images.reduce(async (promise, pdfObj, index, array) => {
      await promise; // Wait for the previous iteration to complete

      const { canvas } = pdfObj;

      // 이미지의 길이와 PDF 페이지의 가로 길이를 기준으로 비율을 구함
      const widthRatio = pageWidth / canvas.width;

      // 비율에 따른 이미지 높이
      const customHeight = canvas.height * widthRatio;

      // 첫 페이지에만 marginTop + 15 적용, 나머지는 marginTop만 적용
      const topMargin = index === 0 ? marginTop + 30 : marginTop;

      // 캔버스를 사용하여 이미지를 페이지 크기로 자르기
      let heightLeft = customHeight; // 남은 이미지 높이
      let position = 0; // 이미지 자를 위치

      while (heightLeft > 0) {
        const sliceHeight = Math.min(pageHeight, heightLeft);

        // 새로운 캔버스 생성
        const newCanvas = document.createElement('canvas');
        newCanvas.width = canvas.width;
        newCanvas.height = sliceHeight / widthRatio;

        // 잘라낸 이미지 부분을 새로운 캔버스에 그림
        const newCtx = newCanvas.getContext('2d');
        newCtx.drawImage(
          canvas,
          0,
          position / widthRatio,
          canvas.width,
          sliceHeight / widthRatio,
          0,
          0,
          canvas.width,
          sliceHeight / widthRatio,
        );

        // 새로운 캔버스의 이미지를 PDF에 추가
        const newImage = newCanvas.toDataURL('image/jpeg');
        doc.addImage(
          newImage,
          'JPEG',
          marginLeft,
          topMargin,
          pageWidth,
          sliceHeight,
        );

        // 남은 이미지 높이와 자를 위치 업데이트
        heightLeft -= sliceHeight;
        position += sliceHeight;

        // 페이지가 남아있는 경우 새 페이지 추가
        if (heightLeft > 0) {
          doc.addPage();
        }
      }

      // 리스트의 마지막 요소가 아닌 경우에만 페이지를 추가
      if (index !== array.length - 1) {
        doc.addPage();
      }
    }, Promise.resolve()); // Initial value for reduce()

    // PDF 문서 저장
    doc.save(
      `report_${format(openDate, 'yyyyMMdd').toString()}_${format(
        closeDate,
        'yyyyMMdd',
      ).toString()}.pdf`,
    );
  } catch (error) {
    console.log('error :: ', error);
    // CustomSwal.fire({
    //   text: 'PDF 다운로드 중 확인되지 않은 오류입니다. 잠시 후 다시 시도해주세요.',
    //   confirmButtonText: '확인',
    // });
    throw error;
  }
};
export default PdfDownloader;
