/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable no-useless-return */
/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { Container, Row, Col, Card, Button } from 'react-bootstrap';
// import { Utils } from '@common';
import {
  // Container as MapDiv,
  // Marker,
  // NaverMap,
  useMap,
  useNavermaps,
} from 'react-naver-maps';

export default React.memo(function HeatMapMarker(props) {
  const { heatmapDataList, opacity, radius, colormap, inReverse } = props;

  const navermaps = useNavermaps();
  const map = useMap();
  const [heatmap, setHeatmap] = useState();

  useEffect(() => {
    let tempHeatmap = new navermaps.visualization.HeatMap({
      map,
      data: heatmapDataList,
      radius: radius || 17, // 히트맵 점의 크기/기존15
      opacity,
    });

    tempHeatmap.setColorMap(colormap, inReverse); // RAINBOW or YIOrRd

    setHeatmap(tempHeatmap);
    return () => {
      tempHeatmap.setMap(null);
    };
  }, []);

  useEffect(() => {
    if (heatmap) {
      heatmap.setData(heatmapDataList);
      if (radius) heatmap.radius = radius;
      heatmap.redraw();
    }
  }, [heatmapDataList, radius]);
});
