import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Utils } from '@common';

ChartJS.register(ArcElement, Tooltip, Legend);
export const updatedOptions = {
  // responsive: true,
  // maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      position: 'nearest',
    },
    datalabels: {
      display: true,
      formatter: (value, context) => {
        const total = context.chart.data.datasets[0].data.reduce(
          (a, b) => a + b,
          0,
        );
        const percentage = `${((value / total) * 100).toFixed(2)}%`;
        return percentage;
      },
      color: '#fff',
      font: {
        weight: 'bold',
      },
    },
  },
};

const labels = ['', '', '', '', '', '', '', '', ''];

export const dataObj = {
  labels,
  datasets: [
    {
      data: [],
      backgroundColor: [
        '#24427a',
        '#f99b2e',
        '#5890c7',
        '#624aef',
        '#2c8a3e',
        '#ff97d6',
        '#00deb5',
        '#eb440f',
        '#bcbbdc',
      ],
      borderWidth: 1,
      // categoryPercentage: 1.0,
      // barPercentage: 0.5,
    },
  ],
};

export function TopDataPieChart({ data = dataObj, sumLabel }) {
  return (
    <div id="piechart">
      {/* <div style={{ height: '234px;', position: 'relative' }}> */}
      <div style={{ position: 'relative' }}>
        <Doughnut data={data} options={updatedOptions} />
        <div className="doughnut-text">
          <p>{sumLabel}</p>
          <h6>{data.sum && Utils.numberCommaPointZeroToThree(data.sum)}</h6>
        </div>
      </div>
    </div>
  );
}
