const CHART_COLORS = [
  {
    borderColor: 'rgb(36, 66, 122)',
    backgroundColor: 'rgb(36, 66, 122)',
  },
  {
    borderColor: 'rgb(249, 155, 46)',
    backgroundColor: 'rgb(249, 155, 46)',
  },
  {
    borderColor: 'rgb(88, 144, 199)',
    backgroundColor: 'rgb(88, 144, 199)',
  },
  {
    borderColor: 'rgb(98, 74, 239)',
    backgroundColor: 'rgb(98, 74, 239)',
  },
  {
    borderColor: 'rgb(44, 138, 62)',
    backgroundColor: 'rgb(44, 138, 62)',
  },
  {
    borderColor: 'rgb(255, 151, 214)',
    backgroundColor: 'rgb(255, 151, 214)',
  },
  {
    borderColor: 'rgb(0, 222, 181)',
    backgroundColor: 'rgb(0, 222, 181)',
  },
  {
    borderColor: 'rgb(235, 68, 15)',
    backgroundColor: 'rgb(235, 68, 15)',
  },
  {
    borderColor: 'rgb(188, 187, 220)',
    backgroundColor: 'rgb(188, 187, 220)',
  },
];

export default CHART_COLORS;
