/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const labels = ['슬라이드배너', '바 배너', '박스 배너', '통 배너'];

export const data = {
  labels,
  datasets: [
    {
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: 'rgb(238, 83, 124)',
      categoryPercentage: 1.0,
      barPercentage: 0.5,
    },
    {
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: 'rgb(235, 146, 123)',
      categoryPercentage: 1.0,
      barPercentage: 0.5,
    },
  ],
};

export const PrmBarChart = React.forwardRef(
  ({ chartData, isDatalabels }, ref) => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          position: 'nearest',
        },
        datalabels: {
          display: !!isDatalabels,
        },
      },
      scales: {
        // y: {
        //   beginAtZero: true,
        //   max: 10,
        //   maxTicksLimit: 5,
        //   ticks: {
        //     stepSize: 250,
        //     max: 10,
        //   },
        // },
      },
    };
    return (
      <div
        style={{
          height: '300px',
          width: '100%',
          display: 'flex',
        }}
      >
        <Bar
          ref={ref}
          options={options}
          data={chartData || data}
          style={{ flex: 1, width: '100%', alignSelf: 'flex-end' }}
        />
      </div>
    );
  },
);
