/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const updatedOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      position: 'nearest',
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        font: {
          weight: 'bold',
        },
      },
    },
  },
};
const backgroundColors = [
  '#24427a',
  '#f99b2e',
  '#5890c7',
  '#624aef',
  '#2c8a3e',
  '#ff97d6',
  '#00deb5',
  '#eb440f',
  '#bcbbdc',
];

const labels = ['', '', '', '', '', '', '', '', ''];

export const dataObj = {
  labels,
  datasets: [
    {
      data: [],
      backgroundColor: labels.map((_, index) => backgroundColors[index]),
      categoryPercentage: 1.0,
      barPercentage: 0.5,
    },
  ],
};

export function VerticalBarChart({ data = dataObj }) {
  return (
    <div
      style={{
        height: '300px',
        width: '100%',
        display: 'flex',
      }}
    >
      <Bar
        options={updatedOptions}
        data={data}
        style={{ flex: 1, width: '100%', alignSelf: 'flex-end' }}
      />
    </div>
  );
}
