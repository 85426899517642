/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/named */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Form,
  Button,
  Table,
  FormGroup,
  FormCheck,
  ListGroup,
} from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomSwal,
} from '@components';
import { Utils } from '@common';
import { images } from '@assets';
import { format } from 'date-fns';
import html2canvas from 'html2canvas';
import PdfDownloader from '../../common/PdfDownloader';

// 스피너
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

// 엑셀 다운
import { saveAs } from 'file-saver';
import * as ExcelJS from 'exceljs';
import { PrmBarChart } from './PrmBarChart';

import './assets/css/ap-data-table.css';
import { getFlowDataLab, getFlowDataLabExcel } from '../../common/crud';
import { VerticalBarChart, dataObj as barData } from './VerticalBarChart';
import { TopDataPieChart, dataObj as pieData } from './TopdataPieChart';

const initBarChartData = {
  labels: [],
  datasets: [],
};

export default React.memo(function DataToCost(props) {
  const [bsunitOptions, setBsUnitOptions] = useState(businessOptions[0]); // 사업단위
  const [topNUnitOptions, setTopNUnitOptions] = useState(topNOptions[0]); // 순위
  const [citydoOptions, setCityDoOptions] = useState(cityOptions[0]); // 시/도
  const [regionOptions, setRegionOptions] = useState(GuOptions[0]); // 시/군/구
  const [provinceOptions, setProvinceOptions] = useState(); // 읍/면/동
  const [openDate, setOpenDate] = useState(
    new Date().setDate(new Date().getDate() - 30),
  ); // 일정
  const [closeDate, setCloseDate] = useState(new Date()); // 일정

  // --- 데이터 사용량 및 절감 비용 체크박스 ------------------
  const [checkedItems, setCheckedItems] = useState({
    usedAPData: true,
    usedAPCost: true,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleCheckboxChange = e => {
    const { id, checked } = e.target;
    setCheckedItems(prevState => {
      const otherKey = id === 'usedAPData' ? 'usedAPCost' : 'usedAPData';
      if (!checked && !prevState[otherKey]) {
        return prevState;
      }
      return {
        ...prevState,
        [id]: checked,
      };
    });
  };

  // console.log('checkedItems: ', checkedItems);
  // -----------------------------------------------------

  const [labels, setLabels] = useState([
    { label: [], color: 'first' },
    { label: [], color: 'second' },
    { label: [], color: 'third' },
    { label: [], color: 'fourth' },
    { label: [], color: 'fifth' },
    { label: [], color: 'sixth' },
    { label: [], color: 'seventh' },
    { label: [], color: 'eighth' },
    { label: [], color: 'etc' },
  ]);

  const [barChartDataUL, setBarChartDataUL] = useState({
    labels: [],
    datasets: [{}],
  });
  const [pieChartDataUL, setPieChartDataUL] = useState({
    labels: [],
    datasets: [{}],
  });
  const [barChartDataDL, setBarChartDataDL] = useState({
    labels: [],
    datasets: [{}],
  });
  const [pieChartDataDL, setPieChartDataDL] = useState({
    labels: [],
    datasets: [{}],
  });
  const [barChartDataULDL, setBarChartDataULDL] = useState({
    labels: [],
    datasets: [{}],
  });
  const [pieChartDataULDL, setPieChartDataULDL] = useState({
    labels: [],
    datasets: [{}],
  });

  // AP 데이터 사용량
  const [usedAPData, setUsedAPData] = useState([]);
  const [usedAPCostData, setUsedAPCostData] = useState([]);
  const [usedAPDataTotal, setUsedAPDataTotal] = useState([]);
  const [usedAPDataCostTotal, setUsedAPDataCostTotal] = useState([]);
  const [usedAPDataBarChartData, setUsedAPDataBarChartData] =
    useState(initBarChartData);

  const [isUsedData, setIsUsedData] = useState(false);
  const [isUsedAPCostData, setIsUsedAPCostData] = useState(false);

  const barlables = [
    {
      label: 'UL',
      color: 'ul',
      rgb: 'rgb(249, 65, 68)',
      rgba: 'rgba(249, 65, 68,0.5)',
    },
    {
      label: 'DL',
      color: 'dl',
      rgb: 'rgb(45, 156, 219)',
      rgba: 'rgba(45, 156, 219,0.5)',
    },
  ];

  const defaultBarOption = {
    categoryPercentage: 1.0,
    barPercentage: 0.5,
  };

  const tableStyle = {
    // 시간별 총합
    usedUpDataUL: {
      backgroundColor: 'rgba(249, 65, 68,0.2)',
    },
    usedDownDataDL: {
      backgroundColor: 'rgba(45, 156, 219,0.2)',
    },
    usedUpData: {
      backgroundColor: 'rgba(249, 65, 68,0.2)',
      borderBottom: 'hidden',
    },
    usedUpCost: {
      backgroundColor: 'rgba(249, 65, 68,0.1)',
    },
    usedDownData: {
      backgroundColor: 'rgba(45, 156, 219,0.2)',
      borderBottom: 'hidden',
    },
    usedDownCost: {
      backgroundColor: 'rgba(45, 156, 219,0.1)',
    },
    // table body
    usedUpDataBodyUL: {
      backgroundColor: 'rgba(249, 65, 68,0.2)',
    },
    usedDownDataBodyDL: {
      backgroundColor: 'rgba(45, 156, 219,0.2)',
    },
    usedUpDataBody: {
      backgroundColor: 'rgba(249, 65, 68,0.2)',
      borderBottom: 'hidden',
    },
    usedUpCostBody: {
      backgroundColor: 'rgba(249, 65, 68,0.1)',
    },
    usedDownDataBody: {
      backgroundColor: 'rgba(45, 156, 219,0.2)',
      borderBottom: 'hidden',
    },
    usedDownCostBody: {
      backgroundColor: 'rgba(45, 156, 219,0.1)',
    },
    // No, AP column에 대한 홀수/짝수 row 스타일
    columnNoAPTotal: {
      backgroundColor: 'rgba(234, 237, 245, 1)',
    },
    columnNoAPOdd: {
      backgroundColor: 'rgba(234, 237, 245, 1)',
    },
    columnNoAPEven: {
      backgroundColor: 'rgba(234, 237, 245, 0.5)',
    },
  };

  const pdfRef1p = useRef();
  const pdfRef2p = useRef();
  const pdfRef3p = useRef();

  const COST_RATE = 0.429; // 1MB 천원 환산

  const isStartDate = format(openDate, 'yyyy-MM-dd').toString();
  const isEndDate = format(closeDate, 'yyyy-MM-dd').toString();

  const tempCallAPI = async (startDate, endDate, profileId, topN) => {
    setUsedAPData([]);
    setUsedAPDataTotal([]);
    setUsedAPDataCostTotal([]);
    setUsedAPDataBarChartData(initBarChartData);

    setIsLoading(true);

    try {
      const url = 'equip';
      const params = { startDate, endDate, profileId, topN };
      const response = (await getFlowDataLab(url, params)).data;

      // console.log(
      //   'startDate, endDate, profileId, topN : ',
      //   startDate,
      //   endDate,
      //   profileId,
      //   topN,
      // );

      const { usedData } = response.data;

      // console.log('usedData : ', usedData);

      // --- apmac별 업로드 합계, 다운로드 합계, 총 합계 -----------------------
      const apmacUsedData = usedData.reduce((acc, item) => {
        const existing = acc.find(entry => entry.apmac_id === item.apmac_id);

        if (existing) {
          existing.sum_rx += item.rx;
          existing.sum_tx += item.tx;
          existing.sum_total += item.rx + item.tx;
        } else {
          acc.push({
            apmac_id: item.apmac_id,
            ap_nm: item.ap_nm,
            sum_rx: item.rx,
            sum_tx: item.tx,
            sum_total: item.rx + item.tx,
          });
        }

        return acc;
      }, []);

      const createSortedArrays = (key, length) => {
        const sortedData = [...apmacUsedData].sort((a, b) => b[key] - a[key]);
        const sliceLength = length === 0 ? sortedData.length : length;
        return {
          ap_nm: sortedData.map(item => item.ap_nm).slice(0, sliceLength),
          [key]: sortedData.map(item => item[key]).slice(0, sliceLength),
        };
      };

      const apmacUsedDataRank = {
        sum_rx: createSortedArrays('sum_rx', topN),
        sum_tx: createSortedArrays('sum_tx', topN),
        sum_total: createSortedArrays('sum_total', topN),
      };

      // --- AP, 레전드, 차트 컬러 맵핑 -----------------------------------
      const colorArr = [
        'first',
        'second',
        'third',
        'fourth',
        'fifth',
        'sixth',
        'seventh',
        'eighth',
        'etc',
      ];

      const backgroundColorsObj = {
        first: '#24427a',
        second: '#f99b2e',
        third: '#5890c7',
        fourth: '#624aef',
        fifth: '#2c8a3e',
        sixth: '#ff97d6',
        seventh: '#00deb5',
        eighth: '#eb440f',
        etc: '#bcbbdc',
      };

      const rankToColorMap = apmacUsedDataRank.sum_total.ap_nm.reduce(
        (acc, item, index) => {
          const colorIndex = index % colorArr.length;
          acc[item] = colorArr[colorIndex];
          return acc;
        },
        {},
      );

      const tempLabelsObj = {
        first: [],
        second: [],
        third: [],
        fourth: [],
        fifth: [],
        sixth: [],
        seventh: [],
        eighth: [],
        etc: [],
      };

      apmacUsedDataRank.sum_total.ap_nm.forEach((label, index) => {
        const colorKey = colorArr[index % colorArr.length];
        tempLabelsObj[colorKey].push(label);
      });

      const initialLabels = colorArr.map(color => ({ label: [], color }));

      apmacUsedDataRank.sum_total.ap_nm.forEach((label, index) => {
        const colorIndex = index % colorArr.length;
        initialLabels[colorIndex].label.push(label);
      });

      setLabels(initialLabels);
      // -----------------------------------------------------------

      const tempBarChartDataUL = {
        labels: apmacUsedDataRank.sum_rx.ap_nm,
        datasets: [
          {
            data: apmacUsedDataRank.sum_rx.sum_rx,
            backgroundColor: apmacUsedDataRank.sum_rx.ap_nm.map(
              el => backgroundColorsObj[rankToColorMap[el]],
            ),
            categoryPercentage: 1.0,
            barPercentage: 0.5,
          },
        ],
      };
      setBarChartDataUL(tempBarChartDataUL);

      const tempSumTotalUL = apmacUsedDataRank.sum_rx.sum_rx.reduce(
        (a, b) => a + b,
        0,
      );

      const tempPieChartDataUL = {
        labels: apmacUsedDataRank.sum_rx.ap_nm,
        datasets: [
          {
            data: apmacUsedDataRank.sum_rx.sum_rx,
            backgroundColor: apmacUsedDataRank.sum_rx.ap_nm.map(
              el => backgroundColorsObj[rankToColorMap[el]],
            ),
            borderWidth: 1,
          },
        ],
        sum: tempSumTotalUL,
      };
      setPieChartDataUL(tempPieChartDataUL);

      // 다운로드 순위 차트
      const tempBarChartDataDL = {
        labels: apmacUsedDataRank.sum_tx.ap_nm,
        datasets: [
          {
            data: apmacUsedDataRank.sum_tx.sum_tx,
            backgroundColor: apmacUsedDataRank.sum_tx.ap_nm.map(
              el => backgroundColorsObj[rankToColorMap[el]],
            ),
            categoryPercentage: 1.0,
            barPercentage: 0.5,
          },
        ],
      };
      setBarChartDataDL(tempBarChartDataDL);

      const tempSumTotalDL = apmacUsedDataRank.sum_tx.sum_tx.reduce(
        (a, b) => a + b,
        0,
      );

      const tempPieChartDataDL = {
        labels: apmacUsedDataRank.sum_tx.ap_nm,
        datasets: [
          {
            data: apmacUsedDataRank.sum_tx.sum_tx,
            backgroundColor: apmacUsedDataRank.sum_tx.ap_nm.map(
              el => backgroundColorsObj[rankToColorMap[el]],
            ),
            borderWidth: 1,
          },
        ],
        sum: tempSumTotalDL,
      };
      setPieChartDataDL(tempPieChartDataDL);

      // 업로드 + 다운로드 순위 차트
      const tempBarChartDataULDL = {
        labels: apmacUsedDataRank.sum_total.ap_nm,
        datasets: [
          {
            data: apmacUsedDataRank.sum_total.sum_total,
            backgroundColor: apmacUsedDataRank.sum_total.ap_nm.map(
              el => backgroundColorsObj[rankToColorMap[el]],
            ),
            categoryPercentage: 1.0,
            barPercentage: 0.5,
          },
        ],
      };
      setBarChartDataULDL(tempBarChartDataULDL);

      const tempSumTotalULDL = apmacUsedDataRank.sum_total.sum_total.reduce(
        (a, b) => a + b,
        0,
      );

      const tempPieChartDataULDL = {
        labels: apmacUsedDataRank.sum_total.ap_nm,
        datasets: [
          {
            data: apmacUsedDataRank.sum_total.sum_total,
            backgroundColor: apmacUsedDataRank.sum_total.ap_nm.map(
              el => backgroundColorsObj[rankToColorMap[el]],
            ),
            borderWidth: 1,
          },
        ],
        sum: tempSumTotalULDL,
      };
      setPieChartDataULDL(tempPieChartDataULDL);
      // -----------------------------------------------------------------------

      // 상태 초기화
      if (usedData.length === 0) {
        setUsedAPData([]);
        setUsedAPDataTotal([]);
        setUsedAPDataCostTotal([]);
        setUsedAPDataBarChartData(initBarChartData);
        return;
      }

      // AP 데이터 사용량 시간별 총합 함수 - 오전, 오후
      const accData = (acc, cur) => {
        const { hh, rx, tx } = cur;

        if (acc[hh] === undefined) {
          acc[hh] = { hh, rx, tx };
        } else {
          acc[hh].rx += rx;
          acc[hh].tx += tx;
        }
        acc.total.rx += rx;
        acc.total.tx += tx;
        if (hh >= 12) {
          acc.totalPm.rx += rx;
          acc.totalPm.tx += tx;
        } else {
          acc.totalAm.rx += rx;
          acc.totalAm.tx += tx;
        }
        return acc;
      };

      // --- AP장비별 데이터 사용량(테이블) - 오전, 오후 ----------
      const resultUsedAPDataAmPmArr = Object.values(
        usedData.reduce((acc, cur) => {
          const { ap_nm: apNm, apmac_id: apmacId, hh, rx, tx } = cur;

          const hhNum = +hh;

          if (acc[apmacId]) {
            Object.assign(acc[apmacId].data[hh], {
              hh: hhNum,
              rx,
              tx,
            });
            acc[apmacId].rx_sum += rx;
            acc[apmacId].tx_sum += tx;
            if (hhNum >= 12) {
              acc[apmacId].rx_pm_sum += rx;
              acc[apmacId].tx_pm_sum += tx;
            } else {
              acc[apmacId].rx_am_sum += rx;
              acc[apmacId].tx_am_sum += tx;
            }
          } else {
            acc[apmacId] = {
              apmac_id: apmacId,
              ap_nm: apNm,
              data: [],
              rx_sum: rx ?? 0,
              tx_sum: tx ?? 0,
              rx_am_sum: rx ?? 0,
              tx_am_sum: tx ?? 0,
              rx_pm_sum: 0,
              tx_pm_sum: 0,
            };

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < 24; i++) {
              if (i === hhNum) {
                acc[apmacId].data.push({
                  hh: i,
                  rx,
                  tx,
                });
                // acc[apmacId].rx_sum += rx;
                // acc[apmacId].tx_sum += tx;
              } else {
                acc[apmacId].data.push({
                  hh: i,
                  rx: 0,
                  tx: 0,
                });
              }
            }
            acc[apmacId] = { ...acc[apmacId] };
          }

          return acc;
        }, {}),
      );

      setUsedAPData(resultUsedAPDataAmPmArr);

      const resultUsedAPDataTotal = resultUsedAPDataAmPmArr.reduce(
        (acc, v) => {
          return v.data.reduce(accData, acc);
        },
        {
          total: { hh: 'total', rx: 0, tx: 0 },
          totalAm: { hh: 'am', rx: 0, tx: 0 },
          totalPm: { hh: 'pm', rx: 0, tx: 0 },
        },
      );

      const resultUsedAPDataTotalArr = [
        ...Object.values(resultUsedAPDataTotal),
      ];

      setUsedAPDataTotal(resultUsedAPDataTotalArr);
      // ----------------------------------------------------------

      // --- AP장비별 데이터 절감비용(테이블) - 오전, 오후 --------------------
      const resultUsedAPDataCostAmPmArr = Object.values(
        usedData.reduce((acc, cur) => {
          const {
            ap_nm: apNm,
            apmac_id: apmacId,
            hh,
            rx: rxMb,
            tx: txMb,
          } = cur;

          const rx = rxMb * COST_RATE;
          const tx = txMb * COST_RATE;

          const hhNum = +hh;

          if (acc[apmacId]) {
            Object.assign(acc[apmacId].data[hh], {
              hh: hhNum,
              rx,
              tx,
            });
            acc[apmacId].rx_sum += rx;
            acc[apmacId].tx_sum += tx;
            if (hhNum >= 12) {
              acc[apmacId].rx_pm_sum += rx;
              acc[apmacId].tx_pm_sum += tx;
            } else {
              acc[apmacId].rx_am_sum += rx;
              acc[apmacId].tx_am_sum += tx;
            }
          } else {
            acc[apmacId] = {
              apmac_id: apmacId,
              ap_nm: apNm,
              data: [],
              rx_sum: rx ?? 0,
              tx_sum: tx ?? 0,
              rx_am_sum: rx ?? 0,
              tx_am_sum: tx ?? 0,
              rx_pm_sum: 0,
              tx_pm_sum: 0,
            };

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < 24; i++) {
              if (i === hhNum) {
                acc[apmacId].data.push({
                  hh: i,
                  rx,
                  tx,
                });
                // acc[apmacId].rx_sum += rx;
                // acc[apmacId].tx_sum += tx;
              } else {
                acc[apmacId].data.push({
                  hh: i,
                  rx: 0,
                  tx: 0,
                });
              }
            }
            acc[apmacId] = { ...acc[apmacId] };
          }

          return acc;
        }, {}),
      );

      setUsedAPCostData(resultUsedAPDataCostAmPmArr);

      const resultUsedAPDataCostTotal = resultUsedAPDataCostAmPmArr.reduce(
        (acc, v) => {
          return v.data.reduce(accData, acc);
        },
        {
          total: { hh: 'total', rx: 0, tx: 0 },
          totalAm: { hh: 'am', rx: 0, tx: 0 },
          totalPm: { hh: 'pm', rx: 0, tx: 0 },
        },
      );

      const resultUsedAPDataCostTotalArr = [
        ...Object.values(resultUsedAPDataCostTotal),
      ];

      setUsedAPDataCostTotal(resultUsedAPDataCostTotalArr);
      // -------------------------------------------------------------------

      // --- AP 데이터 시간별 사용량(바 차트 데이터) --------------------------
      const resultUsedAPDataTotalArrSlice = resultUsedAPDataTotalArr.slice(
        0,
        24,
      );
      const tempUsedAPDataTotalBarChartData = {
        labels: resultUsedAPDataTotalArrSlice
          .filter(v => v.hh !== undefined)
          .map(v => v.hh),
        datasets: [
          {
            ...defaultBarOption,
            backgroundColor: barlables[0].rgb,
            data: resultUsedAPDataTotalArrSlice.map(v => v.rx).slice(0, 24),
          },
          {
            ...defaultBarOption,
            backgroundColor: barlables[1].rgb,
            data: resultUsedAPDataTotalArrSlice.map(v => v.tx).slice(0, 24),
          },
        ],
      };

      setUsedAPDataBarChartData(tempUsedAPDataTotalBarChartData);

      setIsUsedData(usedData.length > 0);
      setIsUsedAPCostData(resultUsedAPDataCostAmPmArr.length > 0);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  const createPdfIamge = async () => {
    setIsLoading(true);
    const tempPdfImages = [];
    const chartCanvas1p = await html2canvas(pdfRef1p.current);
    const chartImageUrl1p = chartCanvas1p.toDataURL('image/png');
    const chartPdfObj1p = {
      canvas: chartCanvas1p,
      image: chartImageUrl1p,
    };
    const chartCanvas2p = await html2canvas(pdfRef2p.current);
    const chartImageUrl2p = chartCanvas2p.toDataURL('image/png');
    const chartPdfObj2p = {
      canvas: chartCanvas2p,
      image: chartImageUrl2p,
    };
    const chartCanvas3p = await html2canvas(pdfRef3p.current);
    const chartImageUrl3p = chartCanvas3p.toDataURL('image/png');
    const chartPdfObj3p = {
      canvas: chartCanvas3p,
      image: chartImageUrl3p,
    };
    tempPdfImages.push(chartPdfObj1p);
    tempPdfImages.push(chartPdfObj2p);
    tempPdfImages.push(chartPdfObj3p);

    tempPdfImages.period = [openDate, closeDate];

    return tempPdfImages;
  };

  const downloadExcel = async (startDate, endDate) => {
    setIsLoading(true);
    // eslint-disable-next-line no-useless-catch
    try {
      const profileId = bsunitOptions.value;

      const url = 'equip';
      const params = { startDate, endDate, profileId };
      // TODO: 접속자 수 => 데이터 절감비용
      const response = await getFlowDataLabExcel(url, params);
      const { data } = response.data;
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Datalab Topdata');

      worksheet.columns = [
        { header: '사업단위', key: 'profile', width: 10 },
        { header: 'AP', key: 'ap_nm', width: 30 },
        { header: 'AP MAC 주소', key: 'apmac_address', width: 17 },
        { header: 'AP 위치 주소', key: 'ap_address', width: 50 },
        { header: 'AP 위치 위도', key: 'ap_lat', width: 12 },
        { header: 'AP 위치 경도', key: 'ap_lon', width: 12 },
        { header: '접속자 수', key: 'count', width: 9 },
        { header: '접속 시간', key: 'datetime', width: 20 },
      ];

      worksheet.getCell('A1').value = '검색조건';
      worksheet.getCell('B1').value = `조회 시작일 : ${startDate}`;
      worksheet.getCell('B2').value = `조회 종료일 : ${endDate}`;
      worksheet.getCell('C1').value = '';
      worksheet.getCell('D1').value = '';
      worksheet.getCell('E1').value = '';
      worksheet.getCell('F1').value = '';
      worksheet.getCell('G1').value = '';
      worksheet.getCell('H1').value = '';

      worksheet.spliceRows(3, 0, ['']);

      worksheet.columns.forEach((col, index) => {
        worksheet.getCell(`${String.fromCharCode(index + 65)}4`).value =
          col.header;
      });

      worksheet.addConditionalFormatting({
        ref: 'A4:H4',
        rules: [
          {
            type: 'expression',
            formulae: ['MOD(ROW()+COLUMN(),1)=0'],
            style: {
              fill: {
                type: 'pattern',
                pattern: 'solid',
                bgColor: { argb: '5890c7' },
              },
            },
          },
        ],
      });

      data.forEach(v => {
        worksheet.addRow(v);
      });

      // 다운로드
      const mimeType = {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      };
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], mimeType);

      const fileName = `datalab_topdata_${format(
        openDate,
        'yyyyMMdd',
      ).toString()}_${format(closeDate, 'yyyyMMdd').toString()}.xlsx`;

      saveAs(blob, fileName);
    } catch (error) {
      throw error;
    }
  };

  const doSearch = () => {
    tempCallAPI(
      isStartDate,
      isEndDate,
      bsunitOptions.value,
      topNUnitOptions.value,
    );
  };

  useEffect(() => {
    // 에러 방지: Cannot destructure property 'data' of 'usedAPCostData[i]' as it is undefined.
    setIsUsedData(false);
    setIsUsedAPCostData(false);

    doSearch();
  }, [bsunitOptions, topNUnitOptions, openDate, closeDate]);

  return (
    <main id="datalab-topdata">
      {isLoading && <Loading />}
      <Header title="데이터 사용량/통신비 절감비용 통계" />
      <Container className="contents container-128">
        <article>
          <div className="title-datalab">
            <h5>사업단위별 조회</h5>
          </div>
          <Form className="search-area">
            <div className="form-flex">
              <Form.Group className="form-group">
                <Form.Label>시/도</Form.Label>
                <CustomSelect
                  options={cityOptions}
                  value={citydoOptions}
                  onChange={setCityDoOptions}
                  className="mw-160"
                  placeholder="선택"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="ms-3">시/군/구</Form.Label>
                <CustomSelect
                  options={GuOptions}
                  value={regionOptions}
                  onChange={setRegionOptions}
                  className="mw-160"
                  placeholder="선택"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>읍/면/동</Form.Label>
                <CustomSelect
                  options={dongOptions}
                  value={provinceOptions}
                  onChange={setProvinceOptions}
                  isDisabled
                  className="mw-160"
                  placeholder="선택"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>사업단위</Form.Label>
                <CustomSelect
                  options={businessOptions}
                  value={bsunitOptions}
                  onChange={setBsUnitOptions}
                  className="mw-160"
                  placeholder="선택"
                />
              </Form.Group>
            </div>
            <Form.Group className="form-group">
              <Form.Label>기간</Form.Label>
              {/* <CustomSelect
                options={dateday}
                value={serchfilter}
                onChange={setSerchFilter}
                className="mw-200"
                placeholder="선택"
              /> */}
              {/* <div className="datepicker-wrap mw-280 ms-3"> */}
              <div className="datepicker-wrap mw-280">
                <CustomDatePicker
                  value={openDate}
                  // minDate={Utils.getTomorrow()}
                  maxDate={closeDate || Utils.getToday()}
                  onChange={e => {
                    setOpenDate(e);
                  }}
                  placeholderText="yyyy.mm.dd"
                />
                <span>-</span>
                <CustomDatePicker
                  value={closeDate}
                  minDate={openDate || Utils.getToday()}
                  maxDate={Utils.getToday()}
                  onChange={setCloseDate}
                  placeholderText="yyyy.mm.dd"
                />
              </div>
              {/* <div className="ms-3">
                <Button onClick={() => doSearch()}>검색</Button>
              </div> */}
            </Form.Group>
          </Form>
        </article>
        <div className="btn-end">
          <Button
            onClick={() => {
              downloadExcel(isStartDate, isEndDate)
                .catch(() => {
                  CustomSwal.fire({
                    text: 'XLSX 다운로드 중 확인되지 않은 오류입니다. 잠시 후 다시 시도해주세요.',
                    confirmButtonText: '확인',
                  });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            <i
              style={{ backgroundImage: `url(${images.icExcel})` }}
              className="xlsx me-2"
            />
            XLSX
          </Button>
          <Button
            onClick={() => {
              createPdfIamge()
                .then(e => {
                  return PdfDownloader(e, e.period);
                })
                .catch(e => {
                  CustomSwal.fire({
                    text: 'PDF 다운로드 중 확인되지 않은 오류입니다. 잠시 후 다시 시도해주세요.',
                    confirmButtonText: '확인',
                  });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            <i className="material-icons me-2">picture_as_pdf</i>
            PDF
          </Button>
        </div>
        <div ref={pdfRef1p}>
          <article className="mt-4 scrollbar aptable">
            <div className="flex-title">
              <h5>데이터 사용량/통신비 절감비용</h5>
              <p>{`단위 : 천원(1MB × ${COST_RATE})`}</p>
            </div>
            {/* {usedAPDataCostTotal.length > 0 && ( */}
            <p>조회 기간 : {`${isStartDate} ~ ${isEndDate}`}</p>
            <br />
            {usedAPData.length > 0 ? (
              <Table className="table-hover text-start mt-3">
                <colgroup>
                  <col width={50} />
                  <col width={150} />
                  <col width={100} />
                </colgroup>
                <thead>
                  <tr>
                    <th>사용 유형</th>
                    <th>사용량(MB)</th>
                    <th>절감비용(천원)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="tr-sum">
                    <td>전체</td>
                    <td>
                      {Utils.numberComma(
                        (usedAPDataTotal[24]?.rx || 0) +
                          (usedAPDataTotal[24]?.tx || 0),
                      )}
                    </td>
                    <td>
                      {Utils.numberComma(
                        (usedAPDataCostTotal[24]?.rx || 0) +
                          (usedAPDataCostTotal[24]?.tx || 0),
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>업로드(UL)</td>
                    <td>{Utils.numberComma(usedAPDataTotal[24]?.rx || 0)}</td>
                    <td>
                      {Utils.numberComma(usedAPDataCostTotal[24]?.rx || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td>다운로드(DL)</td>
                    <td>{Utils.numberComma(usedAPDataTotal[24]?.tx || 0)}</td>
                    <td>
                      {Utils.numberComma(usedAPDataCostTotal[24]?.tx || 0)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            ) : (
              <div className="no-data">
                요청 내역이 없습니다. 금일 데이터의 경우 조회할 수 없습니다.
              </div>
            )}
          </article>
          <article className="mt-4">
            <div className="title-datalab">
              <h5>데이터 사용량 순위</h5>
            </div>
            {isUsedData ? (
              <>
                {' '}
                <Form className="search-area">
                  <Form.Group className="form-group">
                    <Form.Label>순위</Form.Label>
                    <CustomSelect
                      options={topNOptions}
                      value={topNUnitOptions}
                      onChange={setTopNUnitOptions}
                      className="mw-160"
                      placeholder="선택"
                    />
                  </Form.Group>
                </Form>
                <div className="title-labels mt-4">
                  {labels.map((option, index) => (
                    <div
                      key={`${option.label}-${index}`}
                      className="oplabel-flex"
                    >
                      {/* <Form.Label>{option.label}</Form.Label> */}
                      <ListGroup>
                        {option.label.map((el, idx) => {
                          return (
                            <ListGroup.Item
                              key={`${index}-${idx}`}
                              className="list-group-item-custom"
                            >
                              <span className={`option-boll ${option.color}`} />
                              <span className="ms-2 item-text">{el}</span>
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="no-data" colSpan={6}>
                요청 내역이 없습니다.
              </div>
            )}
            <div className="grid-section mb-5">
              <div className="bar-section">
                <div className="flex-title">
                  <h5>사용량 순위(UL+DL)</h5>
                  <p>단위 : MB</p>
                </div>
                <div className="bar-height">
                  {isUsedData ? (
                    <VerticalBarChart data={barChartDataULDL} />
                  ) : (
                    <div className="no-data" colSpan={6}>
                      요청 내역이 없습니다.
                    </div>
                  )}
                </div>
              </div>
              <div className="pie-section">
                <div className="flex-title">
                  <h5>사용량 비율(UL+DL)</h5>
                  <p>단위 : MB</p>
                </div>
                <div className="pie-height">
                  {isUsedData ? (
                    <TopDataPieChart
                      // style={{ flex: 1, width: '100%' }}
                      data={pieChartDataULDL}
                      sumLabel="사용량"
                    />
                  ) : (
                    <div className="no-data" colSpan={6}>
                      요청 내역이 없습니다.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="grid-section mb-5">
              <div className="bar-section">
                <div className="flex-title">
                  <h5>업로드 순위(UL)</h5>
                  <p>단위 : MB</p>
                </div>
                <div className="bar-height">
                  {isUsedData ? (
                    <VerticalBarChart data={barChartDataUL} />
                  ) : (
                    <div className="no-data" colSpan={6}>
                      요청 내역이 없습니다.
                    </div>
                  )}
                </div>
              </div>
              <div className="pie-section">
                <div className="flex-title">
                  <h5>업로드 비율(UL)</h5>
                  <p>단위 : MB</p>
                </div>
                <div className="pie-height">
                  {isUsedData ? (
                    <TopDataPieChart
                      // style={{ flex: 1, width: '100%' }}
                      data={pieChartDataUL}
                      sumLabel="사용량"
                    />
                  ) : (
                    <div className="no-data" colSpan={6}>
                      요청 내역이 없습니다.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="grid-section mb-5">
              <div className="bar-section">
                <div className="flex-title">
                  <h5>다운로드 순위(DL)</h5>
                  <p>단위 : MB</p>
                </div>
                <div className="bar-height">
                  {isUsedData ? (
                    <VerticalBarChart data={barChartDataDL} />
                  ) : (
                    <div className="no-data" colSpan={6}>
                      요청 내역이 없습니다.
                    </div>
                  )}
                </div>
              </div>
              <div className="pie-section">
                <div className="flex-title">
                  <h5>다운로드 비율(DL)</h5>
                  <p>단위 : MB</p>
                </div>
                <div className="pie-height">
                  {isUsedData ? (
                    <TopDataPieChart
                      // style={{ flex: 1, width: '100%' }}
                      data={pieChartDataDL}
                      sumLabel="사용량"
                    />
                  ) : (
                    <div className="no-data" colSpan={6}>
                      요청 내역이 없습니다.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </article>
          <article className="mt-4">
            <div className="bar-section">
              <div className="flex-title">
                <h5>시간대별 사용량</h5>
                <div className="title-labels">
                  {barlables.map((option, i) => (
                    <div className="oplabel-flex" key={`barlable-${i}`}>
                      <span className={`option-boll ${option.color}`} />
                      <Form.Label>{option.label}</Form.Label>
                    </div>
                  ))}
                </div>
              </div>
              {usedAPData.length > 0 ? (
                <div className="bar-height">
                  {usedAPDataBarChartData && (
                    <PrmBarChart chartData={usedAPDataBarChartData} />
                  )}
                </div>
              ) : (
                <div className="no-data">
                  요청 내역이 없습니다. 금일 데이터의 경우 조회할 수 없습니다.
                </div>
              )}
            </div>
          </article>
        </div>
        <div ref={pdfRef2p}>
          <article className="mt-4 scrollbar aptable">
            <div className="flex-title">
              <h5>시간대별 사용량/절감비용(오전)</h5>
            </div>
            <div className="flex-title m-0">
              <Form>
                <FormGroup className="form-group">
                  <FormCheck
                    type="checkbox"
                    label="사용량"
                    id="usedAPData"
                    checked={checkedItems.usedAPData}
                    onChange={handleCheckboxChange}
                    style={{ marginRight: '1rem' }}
                  />
                  <FormCheck
                    type="checkbox"
                    label="절감비용"
                    id="usedAPCost"
                    checked={checkedItems.usedAPCost}
                    onChange={handleCheckboxChange}
                  />
                </FormGroup>
              </Form>
              <p>단위 : 사용량(MB), 절감비용(천원)</p>
            </div>
            <Table className="table-hover text-start">
              <colgroup>
                <col width={50} />
                <col width={120} />
                <col width={50} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={120} />
              </colgroup>
              <thead>
                <tr>
                  <th rowSpan={2} className="bd-right center-text">
                    No
                  </th>
                  <th rowSpan={2} className="bd-right">
                    AP
                  </th>
                  <th rowSpan={2} colSpan={2} className="bd-right">
                    유형
                  </th>
                  <th colSpan={12} className="bd-bottom center-text">
                    시간대별 사용량
                  </th>
                  <th rowSpan={2} className="bd-left td-right">
                    전체사용량
                  </th>
                </tr>
                <tr>
                  <th className="bd-right center-text">00 ~ 01</th>
                  <th className="bd-right center-text">01 ~ 02</th>
                  <th className="bd-right center-text">02 ~ 03</th>
                  <th className="bd-right center-text">03 ~ 04</th>
                  <th className="bd-right center-text">04 ~ 05</th>
                  <th className="bd-right center-text">05 ~ 06</th>
                  <th className="bd-right center-text">06 ~ 07</th>
                  <th className="bd-right center-text">07 ~ 08</th>
                  <th className="bd-right center-text">08 ~ 09</th>
                  <th className="bd-right center-text">09 ~ 10</th>
                  <th className="bd-right center-text">10 ~ 11</th>
                  <th className="bd-right center-text">11 ~ 12</th>
                </tr>
                {usedAPData.length > 0 && (
                  <>
                    <tr className="tr-sum">
                      <td
                        rowSpan={
                          checkedItems.usedAPData && checkedItems.usedAPCost
                            ? 4
                            : checkedItems.usedAPCost
                            ? 4
                            : 2
                        }
                        className="center-text"
                        style={tableStyle.columnNoAPTotal}
                      >
                        -
                      </td>
                      <td
                        rowSpan={
                          checkedItems.usedAPData && checkedItems.usedAPCost
                            ? 4
                            : checkedItems.usedAPCost
                            ? 4
                            : 2
                        }
                        style={tableStyle.columnNoAPTotal}
                      >
                        시간별 총합
                      </td>
                      <td
                        rowSpan={
                          checkedItems.usedAPData && checkedItems.usedAPCost
                            ? 2
                            : checkedItems.usedAPCost
                            ? 2
                            : 1
                        }
                        className="right-text"
                        style={tableStyle.usedUpDataUL}
                      >
                        UL
                      </td>
                      {checkedItems.usedAPData && (
                        <>
                          <td
                            className="right-text"
                            style={tableStyle.usedUpData}
                          >
                            사용량
                          </td>
                          {usedAPDataTotal !== undefined &&
                            usedAPDataTotal.slice(0, 12).map((w, i) => {
                              return (
                                <td
                                  key={`used-am-data-total-ul-${i}`}
                                  // className="right-text"
                                  className="td-right"
                                  style={tableStyle.usedUpData}
                                >
                                  {w.rx && Utils.numberComma(w.rx)}
                                </td>
                              );
                            })}
                          <td
                            className="td-right"
                            style={tableStyle.usedUpData}
                          >
                            {usedAPDataTotal.find(w => w.hh === 'am') !==
                              undefined &&
                              Utils.numberComma(
                                usedAPDataTotal.find(w => w.hh === 'am').rx,
                              )}
                          </td>
                        </>
                      )}
                    </tr>
                    {checkedItems.usedAPCost && (
                      <tr className="tr-sum">
                        <td
                          className="right-text"
                          style={tableStyle.usedUpCost}
                        >
                          절감비용
                        </td>
                        {usedAPDataCostTotal !== undefined &&
                          usedAPDataCostTotal.slice(0, 12).map((w, i) => {
                            return (
                              <td
                                key={`used-am-data-cost-ul-total-${i}`}
                                // className="right-text"
                                className="td-right"
                                style={tableStyle.usedUpCost}
                              >
                                {w.rx && Utils.numberComma(w.rx)}
                              </td>
                            );
                          })}
                        <td className="td-right" style={tableStyle.usedUpCost}>
                          {usedAPDataCostTotal.find(w => w.hh === 'am') !==
                            undefined &&
                            Utils.numberComma(
                              usedAPDataCostTotal.find(w => w.hh === 'am').rx,
                            )}
                        </td>
                      </tr>
                    )}
                    <tr className="tr-sum">
                      <td
                        rowSpan={
                          checkedItems.usedAPData && checkedItems.usedAPCost
                            ? 2
                            : checkedItems.usedAPCost
                            ? 2
                            : 1
                        }
                        className="right-text"
                        style={tableStyle.usedDownDataDL}
                      >
                        DL
                      </td>
                      {checkedItems.usedAPData && (
                        <>
                          <td
                            className="right-text"
                            style={tableStyle.usedDownData}
                          >
                            사용량
                          </td>
                          {usedAPDataTotal !== undefined &&
                            usedAPDataTotal.slice(0, 12).map((w, i) => {
                              return (
                                <td
                                  key={`used-am-data-total-dl-${i}`}
                                  // className="right-text"
                                  className="td-right"
                                  style={tableStyle.usedDownData}
                                >
                                  {w.tx && Utils.numberComma(w.tx)}
                                </td>
                              );
                            })}
                          <td
                            className="td-right"
                            style={tableStyle.usedDownData}
                          >
                            {usedAPDataTotal.find(w => w.hh === 'am') !==
                              undefined &&
                              Utils.numberComma(
                                usedAPDataTotal.find(w => w.hh === 'am').tx,
                              )}
                          </td>
                        </>
                      )}
                    </tr>
                    {checkedItems.usedAPCost && (
                      <tr className="tr-sum">
                        <td
                          className="right-text"
                          style={tableStyle.usedDownCost}
                        >
                          절감비용
                        </td>
                        {usedAPDataCostTotal !== undefined &&
                          usedAPDataCostTotal.slice(0, 12).map((w, i) => {
                            return (
                              <td
                                key={`used-am-data-cost-dl-total-${i}`}
                                // className="right-text"
                                className="td-right"
                                style={tableStyle.usedDownCost}
                              >
                                {w.tx && Utils.numberComma(w.tx)}
                              </td>
                            );
                          })}
                        <td
                          className="td-right"
                          style={tableStyle.usedDownCost}
                        >
                          {usedAPDataCostTotal.find(w => w.hh === 'am') !==
                            undefined &&
                            Utils.numberComma(
                              usedAPDataCostTotal.find(w => w.hh === 'am').tx,
                            )}
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </thead>
              {/* tx: 다운로드, rx: 업로드 */}
              <tbody>
                {isUsedData && isUsedAPCostData ? (
                  usedAPData.map((v, i) => {
                    const {
                      ap_nm: apNm,
                      data,
                      rx_am_sum: rxAmSum,
                      tx_am_sum: txAmSum,
                    } = v;
                    const {
                      data: costData,
                      rx_am_sum: costRxAmSum,
                      tx_am_sum: costTxAmSum,
                    } = usedAPCostData[i];
                    return (
                      <React.Fragment key={`used-am-data-${i}`}>
                        <tr>
                          <td
                            rowSpan={
                              checkedItems.usedAPData && checkedItems.usedAPCost
                                ? 4
                                : checkedItems.usedAPCost
                                ? 4
                                : 2
                            }
                            className="center-text"
                            style={
                              i % 2 === 0
                                ? tableStyle.columnNoAPEven
                                : tableStyle.columnNoAPOdd
                            }
                          >
                            {i + 1}
                          </td>
                          <td
                            rowSpan={
                              checkedItems.usedAPData && checkedItems.usedAPCost
                                ? 4
                                : checkedItems.usedAPCost
                                ? 4
                                : 2
                            }
                            style={
                              i % 2 === 0
                                ? tableStyle.columnNoAPEven
                                : tableStyle.columnNoAPOdd
                            }
                          >
                            {apNm}
                          </td>
                          <td
                            rowSpan={
                              checkedItems.usedAPData && checkedItems.usedAPCost
                                ? 2
                                : checkedItems.usedAPCost
                                ? 2
                                : 1
                            }
                            className="right-text"
                            style={tableStyle.usedUpDataBodyUL}
                          >
                            UL
                          </td>
                          {checkedItems.usedAPData && (
                            <>
                              <td
                                className="right-text"
                                style={tableStyle.usedUpDataBody}
                              >
                                사용량
                              </td>
                              {data !== undefined &&
                                data.slice(0, 12).map((w, j) => {
                                  return (
                                    <td
                                      key={`used-am-data-ul-${j}`}
                                      // className="right-text"
                                      className="td-right"
                                      style={tableStyle.usedUpDataBody}
                                    >
                                      {w.rx && Utils.numberComma(w.rx)}
                                    </td>
                                  );
                                })}
                              <td
                                className="td-right"
                                style={tableStyle.usedUpDataBody}
                              >
                                {rxAmSum && Utils.numberComma(rxAmSum)}
                              </td>
                            </>
                          )}
                        </tr>
                        {checkedItems.usedAPCost && (
                          <tr>
                            <td
                              className="right-text"
                              style={tableStyle.usedUpCostBody}
                            >
                              절감비용
                            </td>
                            {costData !== undefined &&
                              costData.slice(0, 12).map((w, j) => {
                                return (
                                  <td
                                    key={`used-am-costData-ul-${j}`}
                                    // className="right-text"
                                    className="td-right"
                                    style={tableStyle.usedUpCostBody}
                                  >
                                    {w.rx && Utils.numberComma(w.rx)}
                                  </td>
                                );
                              })}
                            <td
                              className="td-right"
                              style={tableStyle.usedUpCostBody}
                            >
                              {costRxAmSum && Utils.numberComma(costRxAmSum)}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td
                            rowSpan={
                              checkedItems.usedAPData && checkedItems.usedAPCost
                                ? 2
                                : checkedItems.usedAPCost
                                ? 2
                                : 1
                            }
                            className="right-text"
                            style={tableStyle.usedDownDataBodyDL}
                          >
                            DL
                          </td>
                          {checkedItems.usedAPData && (
                            <>
                              <td
                                className="right-text"
                                style={tableStyle.usedDownDataBody}
                              >
                                사용량
                              </td>
                              {data !== undefined &&
                                data.slice(0, 12).map((w, j) => {
                                  return (
                                    <td
                                      key={`used-am-data-dl-${j}`}
                                      // className="right-text"
                                      className="td-right"
                                      style={tableStyle.usedDownDataBody}
                                    >
                                      {w.tx && Utils.numberComma(w.tx)}
                                    </td>
                                  );
                                })}
                              <td
                                className="td-right"
                                style={tableStyle.usedDownDataBody}
                              >
                                {txAmSum && Utils.numberComma(txAmSum)}
                              </td>
                            </>
                          )}
                        </tr>
                        {checkedItems.usedAPCost && (
                          <tr>
                            <td
                              className="right-text"
                              style={tableStyle.usedDownCostBody}
                            >
                              절감비용
                            </td>
                            {costData !== undefined &&
                              costData.slice(0, 12).map((w, j) => {
                                return (
                                  <td
                                    key={`used-am-costData-dl-${j}`}
                                    // className="right-text"
                                    className="td-right"
                                    style={tableStyle.usedDownCostBody}
                                  >
                                    {w.tx && Utils.numberComma(w.tx)}
                                  </td>
                                );
                              })}
                            <td
                              className="td-right"
                              style={tableStyle.usedDownCostBody}
                            >
                              {costTxAmSum && Utils.numberComma(costTxAmSum)}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <tr>
                    <td className="no-data" colSpan={16}>
                      요청 내역이 없습니다. 금일 데이터의 경우 조회할 수
                      없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </article>
        </div>
        <div ref={pdfRef3p}>
          <article className="mt-4 scrollbar aptable">
            <div className="flex-title">
              <h5>시간대별 사용량/절감비용(오후)</h5>
            </div>
            <div className="flex-title m-0">
              <Form>
                <FormGroup className="form-group">
                  <FormCheck
                    type="checkbox"
                    label="사용량"
                    id="usedAPData"
                    checked={checkedItems.usedAPData}
                    onChange={handleCheckboxChange}
                    style={{ marginRight: '1rem' }}
                  />
                  <FormCheck
                    type="checkbox"
                    label="절감비용"
                    id="usedAPCost"
                    checked={checkedItems.usedAPCost}
                    onChange={handleCheckboxChange}
                  />
                </FormGroup>
              </Form>
              <p>단위 : 사용량(MB), 절감비용(천원)</p>
            </div>
            <Table className="table-hover text-start">
              <colgroup>
                <col width={50} />
                <col width={120} />
                <col width={50} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={120} />
              </colgroup>
              <thead>
                <tr>
                  <th rowSpan={2} className="bd-right center-text">
                    No
                  </th>
                  <th rowSpan={2} className="bd-right">
                    AP
                  </th>
                  <th rowSpan={2} colSpan={2} className="bd-right">
                    유형
                  </th>
                  <th colSpan={12} className="bd-bottom center-text">
                    시간대별 사용량
                  </th>
                  <th rowSpan={2} className="bd-left td-right">
                    전체사용량
                  </th>
                </tr>
                <tr>
                  <th className="bd-right center-text">12 ~ 13</th>
                  <th className="bd-right center-text">13 ~ 14</th>
                  <th className="bd-right center-text">14 ~ 15</th>
                  <th className="bd-right center-text">15 ~ 16</th>
                  <th className="bd-right center-text">16 ~ 17</th>
                  <th className="bd-right center-text">17 ~ 18</th>
                  <th className="bd-right center-text">18 ~ 19</th>
                  <th className="bd-right center-text">19 ~ 20</th>
                  <th className="bd-right center-text">20 ~ 21</th>
                  <th className="bd-right center-text">21 ~ 22</th>
                  <th className="bd-right center-text">22 ~ 23</th>
                  <th className="bd-right center-text">23 ~ 24</th>
                </tr>
                {usedAPData.length > 0 && (
                  <>
                    <tr className="tr-sum">
                      <td
                        rowSpan={
                          checkedItems.usedAPData && checkedItems.usedAPCost
                            ? 4
                            : checkedItems.usedAPCost
                            ? 4
                            : 2
                        }
                        className="center-text"
                        style={tableStyle.columnNoAPTotal}
                      >
                        -
                      </td>
                      <td
                        rowSpan={
                          checkedItems.usedAPData && checkedItems.usedAPCost
                            ? 4
                            : checkedItems.usedAPCost
                            ? 4
                            : 2
                        }
                        style={tableStyle.columnNoAPTotal}
                      >
                        시간별 총합
                      </td>
                      <td
                        rowSpan={
                          checkedItems.usedAPData && checkedItems.usedAPCost
                            ? 2
                            : checkedItems.usedAPCost
                            ? 2
                            : 1
                        }
                        className="right-text"
                        style={tableStyle.usedUpDataUL}
                      >
                        UL
                      </td>
                      {usedAPDataTotal !== undefined && (
                        <>
                          <td
                            className="right-text"
                            style={tableStyle.usedUpData}
                          >
                            사용량
                          </td>
                          {usedAPDataTotal.slice(12, 24).map((w, i) => {
                            return (
                              <td
                                key={`used-pm-data-ul-pm-${i}`}
                                // className="right-text"
                                className="td-right"
                                style={tableStyle.usedUpData}
                              >
                                {w.rx && Utils.numberComma(w.rx)}
                              </td>
                            );
                          })}
                          <td
                            className="td-right"
                            style={tableStyle.usedUpData}
                          >
                            {usedAPDataTotal.find(w => w.hh === 'pm') !==
                              undefined &&
                              Utils.numberComma(
                                usedAPDataTotal.find(w => w.hh === 'pm').rx,
                              )}
                          </td>
                        </>
                      )}
                    </tr>
                    {checkedItems.usedAPCost && (
                      <tr className="tr-sum">
                        <td
                          className="right-text"
                          style={tableStyle.usedUpCost}
                        >
                          절감비용
                        </td>
                        {usedAPDataCostTotal !== undefined &&
                          usedAPDataCostTotal.slice(12, 24).map((w, i) => {
                            return (
                              <td
                                key={`used-pm-data-cost-ul-total-${i}`}
                                // className="right-text"
                                className="td-right"
                                style={tableStyle.usedUpCost}
                              >
                                {w.rx && Utils.numberComma(w.rx)}
                              </td>
                            );
                          })}
                        <td className="td-right" style={tableStyle.usedUpCost}>
                          {usedAPDataCostTotal.find(w => w.hh === 'am') !==
                            undefined &&
                            Utils.numberComma(
                              usedAPDataCostTotal.find(w => w.hh === 'am').rx,
                            )}
                        </td>
                      </tr>
                    )}
                    <tr className="tr-sum">
                      <td
                        rowSpan={
                          checkedItems.usedAPData && checkedItems.usedAPCost
                            ? 2
                            : checkedItems.usedAPCost
                            ? 2
                            : 1
                        }
                        className="right-text"
                        style={tableStyle.usedDownDataDL}
                      >
                        DL
                      </td>
                      {checkedItems.usedAPData && (
                        <>
                          <td
                            className="right-text"
                            style={tableStyle.usedDownData}
                          >
                            사용량
                          </td>
                          {usedAPDataTotal !== undefined &&
                            usedAPDataTotal.slice(12, 24).map((w, i) => {
                              return (
                                <td
                                  key={`used-pm-data-dl-pm-${i}`}
                                  // className="right-text"
                                  className="td-right"
                                  style={tableStyle.usedDownData}
                                >
                                  {w.tx && Utils.numberComma(w.tx)}
                                </td>
                              );
                            })}
                          <td
                            className="td-right"
                            style={tableStyle.usedDownData}
                          >
                            {usedAPDataTotal.find(w => w.hh === 'pm') !==
                              undefined &&
                              Utils.numberComma(
                                usedAPDataTotal.find(w => w.hh === 'pm').tx,
                              )}
                          </td>
                        </>
                      )}
                    </tr>
                    {checkedItems.usedAPCost && (
                      <tr className="tr-sum">
                        <td
                          className="right-text"
                          style={tableStyle.usedDownCost}
                        >
                          절감비용
                        </td>
                        {usedAPDataCostTotal !== undefined &&
                          usedAPDataCostTotal.slice(12, 24).map((w, i) => {
                            return (
                              <td
                                key={`used-pm-data-cost-dl-total-${i}`}
                                // className="right-text"
                                className="td-right"
                                style={tableStyle.usedDownCost}
                              >
                                {w.tx && Utils.numberComma(w.tx)}
                              </td>
                            );
                          })}
                        <td
                          className="td-right"
                          style={tableStyle.usedDownCost}
                        >
                          {usedAPDataCostTotal.find(w => w.hh === 'am') !==
                            undefined &&
                            Utils.numberComma(
                              usedAPDataCostTotal.find(w => w.hh === 'am').tx,
                            )}
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </thead>
              {/* tx: 다운로드, rx: 업로드 */}
              <tbody>
                {isUsedData && isUsedAPCostData ? (
                  usedAPData.map((v, i) => {
                    const {
                      ap_nm: apNm,
                      data,
                      rx_pm_sum: rxPmSum,
                      tx_pm_sum: txPmSum,
                    } = v;
                    const {
                      data: costData,
                      rx_am_sum: costRxAmSum,
                      tx_am_sum: costTxAmSum,
                    } = usedAPCostData[i];
                    return (
                      <React.Fragment key={`used-pm-data-${i}`}>
                        <tr>
                          <td
                            rowSpan={
                              checkedItems.usedAPData && checkedItems.usedAPCost
                                ? 4
                                : checkedItems.usedAPCost
                                ? 4
                                : 2
                            }
                            className="center-text"
                            style={
                              i % 2 === 0
                                ? tableStyle.columnNoAPEven
                                : tableStyle.columnNoAPOdd
                            }
                          >
                            {i + 1}
                          </td>
                          <td
                            rowSpan={
                              checkedItems.usedAPData && checkedItems.usedAPCost
                                ? 4
                                : checkedItems.usedAPCost
                                ? 4
                                : 2
                            }
                            style={
                              i % 2 === 0
                                ? tableStyle.columnNoAPEven
                                : tableStyle.columnNoAPOdd
                            }
                          >
                            {apNm}
                          </td>
                          <td
                            rowSpan={
                              checkedItems.usedAPData && checkedItems.usedAPCost
                                ? 2
                                : checkedItems.usedAPCost
                                ? 2
                                : 1
                            }
                            className="right-text"
                            style={tableStyle.usedUpDataBodyUL}
                          >
                            UL
                          </td>
                          {checkedItems.usedAPData && (
                            <>
                              <td
                                className="right-text"
                                style={tableStyle.usedUpDataBody}
                              >
                                사용량
                              </td>
                              {data !== undefined &&
                                data.slice(12).map((w, j) => {
                                  return (
                                    <td
                                      key={`used-pm-data-pm-ul-${j}`}
                                      // className="right-text"
                                      className="td-right"
                                      style={tableStyle.usedUpDataBody}
                                    >
                                      {w.rx && Utils.numberComma(w.rx)}
                                    </td>
                                  );
                                })}
                              <td
                                className="td-right"
                                style={tableStyle.usedUpDataBody}
                              >
                                {rxPmSum && Utils.numberComma(rxPmSum)}
                              </td>
                            </>
                          )}
                        </tr>
                        {checkedItems.usedAPCost && (
                          <tr>
                            <td
                              className="right-text"
                              style={tableStyle.usedUpCostBody}
                            >
                              절감비용
                            </td>
                            {costData !== undefined &&
                              costData.slice(12).map((w, j) => {
                                return (
                                  <td
                                    key={`used-pm-costData-ul-${j}`}
                                    // className="right-text"
                                    className="td-right"
                                    style={tableStyle.usedUpCostBody}
                                  >
                                    {w.rx && Utils.numberComma(w.rx)}
                                  </td>
                                );
                              })}
                            <td
                              className="td-right"
                              style={tableStyle.usedUpCostBody}
                            >
                              {costRxAmSum && Utils.numberComma(costRxAmSum)}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td
                            rowSpan={
                              checkedItems.usedAPData && checkedItems.usedAPCost
                                ? 2
                                : checkedItems.usedAPCost
                                ? 2
                                : 1
                            }
                            className="right-text"
                            style={tableStyle.usedDownDataBodyDL}
                          >
                            DL
                          </td>
                          {checkedItems.usedAPData && (
                            <>
                              <td
                                className="right-text"
                                style={tableStyle.usedDownDataBody}
                              >
                                사용량
                              </td>
                              {data !== undefined &&
                                data.slice(12).map((w, j) => {
                                  return (
                                    <td
                                      key={`used-pm-data-pm-dl-${j}`}
                                      // className="right-text"
                                      className="td-right"
                                      style={tableStyle.usedDownDataBody}
                                    >
                                      {w.tx && Utils.numberComma(w.tx)}
                                    </td>
                                  );
                                })}
                              <td
                                className="td-right"
                                style={tableStyle.usedDownDataBody}
                              >
                                {txPmSum && Utils.numberComma(txPmSum)}
                              </td>
                            </>
                          )}
                        </tr>
                        {checkedItems.usedAPCost && (
                          <tr>
                            <td
                              className="right-text"
                              style={tableStyle.usedDownCostBody}
                            >
                              절감비용
                            </td>
                            {costData !== undefined &&
                              costData.slice(12).map((w, j) => {
                                return (
                                  <td
                                    key={`used-pm-costData-dl-${j}`}
                                    // className="right-text"
                                    className="td-right"
                                    style={tableStyle.usedDownCostBody}
                                  >
                                    {w.tx && Utils.numberComma(w.tx)}
                                  </td>
                                );
                              })}
                            <td
                              className="td-right"
                              style={tableStyle.usedDownCostBody}
                            >
                              {costTxAmSum && Utils.numberComma(costTxAmSum)}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <tr>
                    <td className="no-data" colSpan={16}>
                      요청 내역이 없습니다. 금일 데이터의 경우 조회할 수
                      없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </article>
        </div>
      </Container>
    </main>
  );
});

function Loading() {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
      }}
    >
      <FontAwesomeIcon icon={faSpinner} spin size="7x" />
    </div>
  );
}

const businessOptions = [
  { label: '전체', value: 0 },
  { label: '창원_2차', value: 1 },
  { label: '창원_1차', value: 2 },
];
const topNOptions = [
  { label: '전체', value: 0 },
  { label: 'TOP 4', value: 4 },
  { label: 'TOP 10', value: 10 },
  { label: 'TOP 30', value: 30 },
  { label: 'TOP 50', value: 50 },
  { label: 'TOP 100', value: 100 },
];
const cityOptions = [{ label: '창원시', value: '1' }];
const GuOptions = [{ label: '의창구', value: '1' }];
const dongOptions = [];
const dateday = [
  { label: '일별', value: '1' },
  { label: '월별', value: '2' },
  { label: '년별', value: '3' },
];
